// @import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");
@use "variables/colors" as *;
@forward "variables/colors";
@import "variables/colors";
@import "~bootstrap";
@import "../assets/fonts/Lato/Lato.scss";
@import url("https://use.typekit.net/csk6jmn.css");
// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");

$font-size: 16px;

// * {
//   outline: 1px solid limegreen;
// }

body {
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: $font-size;
  color: $color-blue;
  background: $color-blue;
  font-weight: 400;
  line-height: 20px;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
}
.link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.object-fit {
  object-fit: contain;
}

h1 {
  padding: 0;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

p {
  margin-bottom: 10px;
}

a {
  color: #678cfc;
  cursor: pointer;
}

a,
input,
.form-control {
  box-shadow: none !important;
}

.footer-block {
  position: absolute;
  background: $color-lightbg-2;
  text-align: center;
  top: -36px;
  border-top-left-radius: 52px;
  border-top-right-radius: 52px;
  cursor: pointer;
  p {
    margin: 0.5rem 0 0.5rem 0;
    color: $color-blue-light;
    font-family: freight-sans-pro;
  }
  @media (max-width: 600px) {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}

input,
.form-control {
  &:focus {
    background: transparent;
  }
}
.arrow-down {
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: $color-lightbg;
  z-index: 10;
}

.dark-blue-bg {
  background: $color-blue;
}

#wrapper {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  display: block;
  z-index: 2;
}

.mainBg {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -2;
  overflow: hidden;
  display: block;
  top: 0;

  img,
  video {
    min-width: 100%;
    height: 100vh;
    width: 100vw;
    object-fit: fill;

    &.signupVideo {
      min-height: 740px;
      width: auto;
    }
  }
}

.gradDiv {
  width: 100%;
  height: 100%;
  float: left;
  position: absolute;
  background: url("../assets/images/svg/rectangle.svg") no-repeat;
  z-index: -1;
  background-size: cover;
}

.gradDivImg {
  width: 100%;
  height: 100%;
  float: left;
  position: fixed;
  background-blend-mode: lighten;
  background-image: linear-gradient(to bottom, rgba(148, 153, 160, 0), #ffffff);
  opacity: 0.5;
  z-index: -1;
}

.navbar {
  .nav-item {
    position: relative;

    .noti-show {
      position: absolute;
      right: 0;
      top: 100%;
      width: 959px;
      z-index: 999;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      background-color: $color-white;
      border-radius: 8px;
      padding: 10px;
      margin-top: 13px;
      margin-right: -117px;
      cursor: default;

      &::after,
      &::before {
        position: absolute;
        content: "";
        right: 117px;
        top: -9px;
        border-radius: 2px;
        border-bottom: 10px solid #e6e9f1;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }

      &::before {
        border-bottom: 9px solid rgba(0, 0, 0, 0.5);
      }

      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
          margin-top: 4px;
          padding: 8px 0;

          &.read {
            // border: 1px solid $color-blue-lightest;
            background: $color-white;
            padding: 8px;
            border-radius: 8px;
          }

          &.unread {
            // border: 1px solid $color-blue-lightest;
            background: $color-blue-lightest-10;
            padding: 8px;
            border-radius: 8px;
          }
        }
      }
    }

    .badge {
      position: absolute;
      top: 0px;
      left: 12px;
      background: $color-pink !important;
    }

    // &.transparent {
    //   .nav-link {
    //     background: url("../assets/images/new-svg/downarrow.svg") no-repeat right
    //       0;
    //   }
    // }
  }

  @media only screen and (max-width: 1024px) {
    .noti-show {
      width: 700px !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .noti-show {
      display: none !important;
    }
  }
}

/* @import 'header.scss'; */

#home nav.navbar .nav-item .nav-link,
#home nav.navbar .nav-item input {
  margin: 0;
  margin-top: 7px;
}

.content {
  background: #fff;
  padding: 40px;
}

.midDiv {
  background: #fff;
  padding: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  width: 590px;
}

.centerDiv {
  padding: 30px;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.sidebarbuyer,
.sidebar {
  width: 72px;
  text-align: center;
  background: #526783;
  background: -moz-linear-gradient(
    top,
    #526783 0%,
    $color-blue 90%,
    $color-blue 100%
  );
  background: -webkit-linear-gradient(
    top,
    #526783 0%,
    $color-blue 90%,
    $color-blue 100%
  );
  background: linear-gradient(
    to bottom,
    #526783 0%,
    $color-blue 90%,
    $color-blue 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#526783', endColorstr='$color-blue', GradientType=0);
  z-index: 0;
  min-height: calc(100vh - 65px);

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      a {
        display: block;
        padding: 13px 0;

        &:hover,
        &.active {
          background: $color-blue-lightest;
          position: relative;

          &::before {
            position: absolute;
            border-left: 5px solid #e55e6c;
            content: "";
            left: 0;
            top: 0;
            height: 100%;
            width: 5px;
          }
        }
      }

      &#switch {
        position: relative;
        margin: 20px 0;

        input {
          opacity: 0;
          position: absolute;
          z-index: 9;

          & + label {
            position: relative;
            display: block;
            margin: 0 15px;
            border: 1px solid $color-primary;
            z-index: 9;
            color: #e6e9f1;
            background: #fff;

            &::before {
              position: absolute;
              content: "";
              left: 0;
              top: 0;
              height: 52%;
              width: 100%;
              background: $color-primary;
              z-index: -1;
              transition: 0.2s;
            }
          }

          &:checked {
            & + label {
              border-color: $color-sky;

              &:before {
                top: 48%;
                background: $color-sky;
              }
            }
          }
        }
      }

      &.yellow {
        a:hover::before,
        a.active::before {
          border-color: #f8e71c;
        }
      }

      &.red {
        a:hover::before,
        a.active::before {
          border-color: #e55e6c;
        }
      }

      &.orange {
        a:hover::before,
        a.active::before {
          border-color: #f8861c;
        }
      }

      &.blue {
        a:hover::before,
        a.active::before {
          border-color: #4a90e2;
        }
      }

      &.blue-light {
        a:hover::before,
        a.active::before {
          border-color: #b5a8ff;
        }
      }

      &.green {
        a:hover::before,
        a.active::before {
          border-color: #50e3c2;
        }
      }

      &.pink {
        a:hover::before,
        a.active::before {
          border-color: #ffa7d1;
        }
      }

      &.pink-dark {
        a:hover::before,
        a.active::before {
          border-color: #de1382;
        }
      }

      &.purple {
        a:hover::before,
        a.active::before {
          border-color: #494e91;
        }
      }

      &.aqua {
        a:hover::before,
        a.active::before {
          border-color: #16b3af;
        }
      }

      // &:nth-child(2) a:hover::before,
      // &:nth-child(2) a.active::before{
      //   border-color: #f8e71c;
      // }
      // &:nth-child(3) a:hover::before,
      // &:nth-child(3) a.active::before{
      //   border-color: #e55e6c;
      // }
      // &:nth-child(4) a:hover::before,
      // &:nth-child(4) a.active::before{
      //   border-color: #4a90e2;
      // }
      // &:nth-child(5) a:hover::before,
      // &:nth-child(5) a.active::before{
      //   border-color: #b5a8ff;
      // }
      // &:nth-child(6) a:hover::before,
      // &:nth-child(6) a.active::before{
      //   border-color: #50e3c2;
      // }
      // &:nth-child(7) a:hover::before,
      // &:nth-child(7) a.active::before{
      //   border-color: #ffa7d1;
      // }
    }
  }
}

.topNav ul {
  padding: 23px;
  margin: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}

.topNav li {
  padding-left: 24px;
  position: relative;
}

.topNav span.badge {
  position: absolute;
  right: -10px;
  top: 0;
}

.middle {
  width: 638px;
  color: #fff;
  position: relative;

  .form-group {
    margin-top: 0;
  }
}

input[type="number"] {
  -moz-appearance: none;
}

.middle .input-group .input-group-prepend span,
.middle .input-group .input-group-append span {
  background: transparent;
  border: 0;
  color: #fff;
  padding: 0;
}

.middle .input-group .input-group-append {
  margin-left: 10px;
}

.middle .input-group .input-group-prepend {
  margin-right: 10px;
}

.middle .form-check input[type="checkbox"]:checked + label:before,
.middle .form-check input[type="checkbox"]:checked + label:after {
  border-color: #fff;
}

.middle .has-error .form-control {
  border-color: #fff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 76px;
  height: 76px;
  margin-right: 20px;
}

.upload-btn-wrapper img.imgAttached {
  width: 100%;
  min-height: 100%;
}

.upload-btn-wrapper .btn {
  border: 0;
  background: $color-blue-lightest;
  padding: 0px;
  width: 76px;
  height: 76px;
  display: inline-block;
  cursor: pointer;
}

.upload-btn-wrapper .btn img {
  transform: rotate(-45deg);
  margin-top: -5px;
}

.upload-btn-wrapper input[type="file"] {
  font-size: 0px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.upload-btn-wrapper:hover .delete {
  display: block;
  cursor: pointer;
}

.upload-btn-wrapper .delete {
  display: none;
  background: #ff5151;
  color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.7;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 25px 0;
}

.accountDetails {
  position: relative;
}

.accountDetails .editDiv {
  position: absolute;
  top: -10px;
  right: 0px;
  z-index: 1;
  width: 200px;
}

.default {
  font-size: $font-size;
  color: #4ee17b;
  position: relative;
}

.default::before {
  content: "";
  left: -12px;
  top: 6px;
  width: 5px;
  height: 10px;
  border: 2px solid #4ee17b;
  position: absolute;
  transform: rotate(45deg) skew(10deg);
  border-left: 0;
  border-top: 0;
}

.greenDiv {
  padding: 14px;
  background: #4ee17b;
  border-radius: 20px;
  position: absolute;
  right: 0;
  top: 50px;
  width: 231px;
  margin-right: 30px;
  color: $color-blue;
}

.borderDiv {
  padding: 20px;
  border: 1px dashed #526783;
  position: absolute;
  left: 100%;
  top: 76px;
  width: 331px;
  margin-left: 5px;
  font-size: 14px;
}

.labelInput {
  position: relative;
}

.labelInput label {
  position: absolute;
  pointer-events: none;
  left: 5px;
  right: auto;
  top: 0px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.labelInput input:focus ~ label,
.labelInput input:valid ~ label,
.labelInput input:not(:empty) ~ label,
.labelInput input:disabled:not(:empty) ~ label,
.labelInput input:read-only ~ label {
  top: 30px;
  left: auto;
  right: 0;
  font-size: 12px;
}

.labelInput input:read-only {
  background: none;
  border: 0;
  text-align: right;
}

/* Footer CSS */
footer {
  clear: both;
  background: $color-blue;
  padding-top: 62px;
  padding-bottom: 65px;

  .container {
    color: #fff;
    font-size: 14px;
    h6 {
      color: #16b3af;
      border-bottom: 1px solid #16b3af;
      padding: 10px 0;
      font-size: 20px;
      font-weight: bold;
    }

    ul {
      padding: 0;
      margin: 0;

      li {
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: relative;

        a {
          color: #fff;
          text-shadow: 0px 0px 2px #000;
          position: relative;
          &::before {
            content: "";
            height: 1px;
            width: 100%;
            top: 100%;
            position: absolute;
            background-color: $color-cyan;
            transform: scale(0);
            transition: 0.3s all;
            display: block;
          }

          &:hover {
            color: $color-cyan;
            text-decoration: none;
            &::before {
              transform: scale(1);
            }
          }
        }
      }
    }
    &.fBot {
      ul {
        li {
          display: inline-block;
          padding: 0 10px;
          a {
            font-size: 17px;
          }
          & + li {
            border-left: 1px solid #526783;
          }
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
    .fTop {
      ul {
        margin-bottom: 20px;
        li {
          display: block;
          padding: 10px 0;
          a {
            display: block;
            position: relative;
            font-size: 18px;
            padding-right: 5px;
            background: url("../assets/images/svg/arrow-footer.svg") no-repeat
              right;
            &::before {
              transform-origin: left;
            }
          }
        }
      }
      [class^="col-"] {
        div + div {
          margin-top: 95px;
        }
      }
    }
  }

  nav.navbar {
    padding: 14px;

    .nav-link {
      font-weight: normal;
      margin-left: 55px;
    }
  }
}
#newsletter {
  padding: 40px 0;
  background: #f6f9fc;
  h6 {
    font-size: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-weight: bold;
  }
  input[type="email"],
  input[type="email"]:focus {
    background: #fff;
    border: 0;
    padding: 14px;
    width: 100%;
    text-align: center;
    border: 1px solid #c0c8d4;
    border-right: 0;
    font-size: 14px;
    line-height: 20px;
  }
  input[type="email"]:focus {
    outline: none;
    box-shadow: 0 0 3px #b4b4b4;
  }
  .btn {
    height: 50px;
    font-size: 14px;
  }
}
.primarybg-lightest {
  background-color: $color-primary-lightest;
}

.whitebg {
  background: #fff;
}

.whitebg-2 {
  background: $color-lightbg-2;
}

.whitebg-3 {
  background: $color-blue-lightest-2;
}

.bluebg {
  background: $color-blue;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #e6e9f1;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  width: 100%;
  position: relative;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

footer.container {
  margin-top: 62px;
  margin-bottom: 65px;
  color: #fff;
  font-size: 14px;
}

footer.container ul,
footer.container li {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* Seller flow CSS */
.seller {
  // width: 660px;
  color: #fff;
  margin: 20px auto;
  clear: both;
}

/* Stepper CSS */
.stepper {
  margin-bottom: 30px;
  font-size: 14px;

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;

    li {
      display: inline-block;
      padding: 0;
      margin: 0;
      text-align: center;
      position: relative;
      flex: 1 1 0 !important;
      flex-grow: 1;
      z-index: 0;

      &::before {
        position: absolute;
        content: "";
        top: 12px;
        left: 0;
        margin-left: 47%;
        border-bottom: 1px solid #526783;
        width: 99%;
        z-index: 1;
      }

      &.fifty:after {
        position: absolute;
        content: "";
        top: 11px;
        left: 47%;
        border-bottom: 1px solid #4ee17b;
        width: 99%;
        z-index: 2;
        width: 50%;
        transition: all 2s;
      }

      &.seventy:after {
        position: absolute;
        content: "";
        top: 11px;
        left: 47%;
        border-bottom: 1px solid #4ee17b;
        width: 99%;
        z-index: 2;
        width: 70%;
      }

      &.ninty:after {
        position: absolute;
        content: "";
        top: 11px;
        left: 47%;
        border-bottom: 1px solid #4ee17b;
        width: 99%;
        z-index: 2;
        width: 90%;
      }

      &:last-child:before {
        border: 0;
      }

      &.active {
        .steps {
          background: url("../assets/images/svg/oval-white.svg") no-repeat;
        }

        .info {
          color: #fff;
        }

        span.details {
          color: #fff;
        }
      }

      &.success {
        .steps {
          background: url("../assets/images/svg/oval-green.svg") no-repeat;
          color: #4ee17b;

          &:after {
            background: url("../assets/images/svg/icn-tick.svg") no-repeat;
            content: "";
            width: 20px;
            height: 20px;
            left: 1px;
            top: 2px;
            position: absolute;
          }
        }

        &:before {
          border-color: #4ee17b !important;
        }
      }

      span.info {
        display: block;
        padding-top: 60px;
        color: #526783;
        margin-top: 10px;
      }

      span.steps {
        background: url("../assets/images/svg/oval-lightblue.svg") no-repeat;
        color: $color-blue;
        width: 20px;
        height: 20px;
        line-height: 19px;
        display: inline-block;
        border-radius: 50%;
        z-index: 9;
        position: relative;
        font-size: 12px;
        font-weight: 700;
      }

      &:nth-child(1) span.info {
        background: url("../assets/images/svg/icn-60-px-inactive-personal-details.svg")
          no-repeat center 10px;
        background-size: 40px;
      }

      &:nth-child(2) span.info {
        background: url("../assets/images/svg/icn-60-px-inactive-briefcase.svg")
          no-repeat center 10px;
        background-size: 40px;
      }

      &:nth-child(3) span.info {
        background: url("../assets/images/svg/icn-60-px-inactive-money.svg")
          no-repeat center 15px;
        background-size: 40px;
      }
      &:nth-child(4) span.info {
        background: url("../assets/images/svg/icn-60-px-inactive-video-verfication.svg")
          no-repeat center 15px;
        background-size: 40px;
      }
      &.active:nth-child(1) span.info {
        background-image: url("../assets/images/svg/icn-60-px-active-personal-details.svg");
      }

      &.active:nth-child(2) span.info {
        background-image: url("../assets/images/svg/icn-60-px-active-briefcase.svg");
      }

      &.active:nth-child(3) span.info {
        background-image: url("../assets/images/svg/icn-60-px-active-money.svg");
      }
      &.active:nth-child(4) span.info {
        background-image: url("../assets/images/svg/icn-60-px-active-video-verfication.svg");
      }
      span.details {
        display: block;
        padding-top: 20px;
        color: $color-blue-lightest;
      }
    }

    &.four li {
      width: 25%;
    }
  }
}

.stepperVertical {
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 0;
      margin: 0;
      list-style-type: none;
      position: relative;
      padding-left: 30px;
      padding-bottom: 10px;
      border-left: 2px solid rgba(22, 179, 175, 0.6);
      margin-left: 35px;
      color: $color-blue;
      z-index: 1;
      &:last-child {
        padding-bottom: 0;
      }

      &::before {
        position: absolute;
        content: "";
        left: -35px;
        top: 4px;
        width: 16px;
        height: 16px;
        // background: url(../assets/images/svg/icn-dropdown.svg) no-repeat;
        transform: rotate(-180deg);
      }

      .steps {
        background: url("../assets/images/svg/Check_Gray.svg") no-repeat;
        color: $color-blue;
        width: 20px;
        height: 20px;
        line-height: 19px;
        display: inline-block;
        border-radius: 50%;
        z-index: 9;
        position: relative;
        font-size: 12px;
        font-weight: 700;
        text-align: center;
        position: absolute;
        left: -10px;
        top: 0;
        color: #fff;
      }

      &.success {
        .steps {
          background: url("../assets/images/svg/oval-cyan.svg") no-repeat;
          // color: #4ee17b;

          &:after {
            // background: url("../assets/images/svg/icn-tick.svg") no-repeat;
            content: "";
            width: 20px;
            height: 20px;
            left: 1px;
            top: 2px;
            position: absolute;
          }
        }

        &:before {
          border-color: #4ee17b;
        }
      }

      &.active {
        color: $color-blue-lightest;

        .steps {
          background: url("../assets/images/svg/Check_Gray.svg") no-repeat;
          color: #fff;
        }
      }

      &.open {
        &::before {
          transform: rotate(360deg);
          top: 0px;
        }
      }
    }
  }
}

img.imgDetails {
  max-width: 100px;
  width: 100%;
  border: 1px solid #b4b4b4;
  padding: 5px;
}

.headingRow {
  padding: 15px 0;
  font-weight: bold;
  border-bottom: 1px solid #c2c2c2;

  span {
    position: relative;
    display: inline-block;
    padding-right: 10px;
    font-size: 14px;
    cursor: pointer;

    &::after,
    &::before {
      position: absolute;
      content: "";
      right: 0;
      top: 10px;
      width: 5px;
      height: 5px;
      display: inline-block;
      border-bottom: 1px solid #c2c2c2;
      border-right: 1px solid #c2c2c2;
      transform: rotate(45deg);
    }

    &::after {
      margin-top: -3px;
      transform: rotate(-135deg);
    }

    &.sortACN {
      &::before {
        display: none;
      }

      &::after {
        margin-top: 0;
      }
    }

    &.sortDCN {
      &::after {
        display: none;
      }

      &::before {
        margin-top: -2px;
      }
    }
  }

  .text-center {
    span {
      padding: 0;

      &::before,
      &::after {
        right: -10px;
      }
    }
  }
}

.bodyRow {
  & > [class^="col-"] {
    border-bottom: 1px solid #c2c2c2;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.detailsRow {
  background: #f2f2f2;
  padding: 15px 0;
}

.withBorderMsg {
  border-left: 1px solid #c2c2c2;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  left: 3.2%;
}

.withArrow {
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    border-bottom: 1px solid #5d6979;
    border-right: 1px solid #5d6979;
    right: 0;
    top: 5px;
    transform: rotate(-45deg);
  }
}

/* Form Control CSS */
.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $color-blue-lighter;
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $color-blue-lighter;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $color-blue-lighter;
}

ng-select.form-control {
  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }

  .ng-placeholder {
    color: $color-blue-lighter;
  }
  &.ng-select-opened {
    border-color: $color-cyan;
  }
}

input.form-control {
  &::placeholder {
    color: $color-blue-lighter;
  }

  &:-ms-input-placeholder {
    color: $color-blue-lighter;
  }

  &.withBorder {
    background: #fff;
    border: 1px solid $color-blue-lightest;
  }
}

input:disabled.form-control,
[readonly].form-control {
  background: none;
}

input.ng-touched.ng-invalid.form-control:-ms-input-placeholder {
  color: #97a4b5;
}

marketplace-datepicker.ng-invalid.ng-touched {
  .form-control {
    border-color: $color-red !important;
  }
}

.floatingLabel {
  position: relative;

  .form-control {
    &::placeholder {
      color: transparent;
    }

    &:-ms-input-placeholder {
      color: transparent;
    }

    & + {
      label {
        position: absolute;
        left: 0;
        top: 0;
        transition: all 0.5s;
        color: $color-blue-lighter;
        pointer-events: none;
        margin: 0;
      }
    }

    &.ng-valid {
      border-color: $color-blue;

      & + label {
        top: -15px;
        font-size: 10px;
        color: $color-blue;
      }
    }

    &.ng-dirty {
      border-color: $color-sky;

      & + label {
        top: -15px;
        font-size: 10px;
        color: $color-sky;
      }
    }

    &.ng-touched {
      border-color: $color-sky;

      &::placeholder {
        color: transparent;
      }

      .ng-placeholder {
        color: transparent !important;
      }
    }
    &:focus + label,
    &.ng-select-focused + label {
      top: -15px;
      font-size: 10px;
      color: $color-sky;
    }

    &.ng-valid,
    &.ng-select-focused {
      .ng-placeholder {
        display: none;
      }
    }

    &.ng-invalid.ng-touched {
      border-color: $color-red;

      & + label {
        top: -15px;
        font-size: 10px;
        color: $color-red;
      }
    }

    &.ng-valid.ng-touched {
      border-color: $color-green;

      & + label {
        top: -15px;
        font-size: 10px;
        color: $color-green;
      }
    }
  }

  .labelOnTop {
    position: absolute;
    left: 0;
    top: 0;
    top: -15px;
    font-size: 10px;
    color: $color-blue;

    & + .form-control {
      &::placeholder {
        color: $color-blue-lighter;
      }

      &:-ms-input-placeholder {
        color: $color-blue-lighter;
      }

      .ng-placeholder {
        color: $color-blue-lighter;
      }

      &.ng-invalid.ng-touched {
        &::placeholder {
          color: transparent;
        }

        &:-ms-input-placeholder {
          color: transparent;
        }

        .ng-placeholder {
          color: transparent;
        }
      }

      &.ng-invalid.ng-touched.withPlaceholder {
        &::placeholder {
          color: $color-blue-lighter !important;
        }

        &:-ms-input-placeholder {
          color: $color-blue-lighter !important;
        }

        .ng-placeholder {
          color: $color-blue-lighter !important;
        }
      }
    }
  }

  .hasErrorSpan {
    position: absolute;
    left: 0;
    top: 100%;
    line-height: 14px;
    transition: all 0.5s;
  }
}

.form-group {
  margin-bottom: 20px;
  margin-top: 10px;
}

.form-control {
  border: 0;
  padding: 0;
  border-radius: 0;
  border-bottom: 1px solid $color-blue-lighter;
  font-family: "Lato", Arial, Helvetica, sans-serif;
  font-size: $font-size;
  line-height: 20px;
  height: 22px;
  color: $color-blue;
  background: transparent;
}

.form-control:focus,
.form-control:-internal-autofill-selected {
  box-shadow: 0 0 0px #000;
  background-color: transparent;
}

.form-control:disabled {
  background: none;
  opacity: 0.5;
  cursor: not-allowed;
}

.ng-select.ng-select-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.ng-select.ng-select-disabled .ng-select-container {
  pointer-events: none;

  .ng-value-container .ng-placeholder {
    display: none !important;
  }
}

.ng-select.ng-select-disabled#box_export_country
  .ng-select-container
  .ng-value-container
  .ng-placeholder {
  display: block !important;
}

.searchFilter {
  position: relative;
  display: inline-block;
  max-width: 250px;

  input {
    border: 1px solid $color-blue-lightest;
    padding: 6px 25px;
    padding-right: 30px;
    width: 100%;
    min-width: 189px;
    border-radius: 8px;
  }

  &::after {
    width: 20px;
    height: 20px;
    content: " ";
    background: url("../assets/images/svg/magnify-glass.svg") no-repeat right;
    background-size: 100%;
    opacity: 0.7;
    position: absolute;
    left: 5px;
    top: 7px;
  }
  &.width260 {
    width: 260px;
    max-width: 260px;
  }
}

select.form-control {
  padding-right: 25px;
  padding-bottom: 0px;
  padding-top: 0px;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  -ms-appearance: none;
  /* IE */
  appearance: none;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat right;
  background-size: 16px;
  color: $color-blue;
}

select::-ms-expand {
  display: none;
}

.form-control::-ms-clear {
  display: none;
}

.inputWithBg {
  position: relative;

  .form-control,
  &:focus {
    background-color: #fff !important;
    border: 1px solid $color-blue-lightest !important;
  }

  span.info {
    position: absolute;
    right: 5px;
    bottom: 7px;
    color: $color-blue-lighter;
  }
}

.form-check {
  input[type="checkbox"] {
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 15px;
    height: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    & + label {
      position: relative;
      margin: 0;

      &:before {
        position: absolute;
        content: " ";
        width: 16px;
        height: 16px;
        border: 1px solid $color-blue-lightest;
        left: -20px;
        top: 2px;
      }

      &.sm::before {
        width: 10px;
        height: 10px;
        top: 5px;
      }

      &.top2px::before {
        top: 2px;
      }
    }

    &:checked + label.top2px::after {
      top: 6px;
    }

    &:focus + label:before {
      border-color: #4a90e2;
    }

    &:checked + label:before {
      border-color: $color-blue;
    }

    &:checked + label:after {
      position: absolute;
      content: " ";
      width: 10px;
      height: 10px;
      background-color: $color-blue-lightest;
      left: -17px;
      top: 5px;

      // border-bottom: 3px solid $color-blue;
      // border-left: 3px solid $color-blue;
      // left: -17px;
      // top: 6px;
      // transform: rotate(-45deg);
    }

    &:checked + label.sm:after {
      border-width: 2px;
      left: -20px;
    }
    &:disabled:hover + label {
      cursor: not-allowed !important;
    }
    &:disabled + label:before {
      opacity: 50%;
    }
    &:disabled + label:after {
      opacity: 50%;
    }
  }

  input[type="radio"] {
    opacity: 0;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 16px;
    position: absolute;

    & + label {
      position: relative;

      &::before {
        position: absolute;
        content: " ";
        width: 10px;
        height: 10px;
        border: 1px solid $color-blue;
        left: -20px;
        top: 4px;
        border-radius: 50%;
      }
    }

    &:checked + label:after {
      position: absolute;
      content: " ";
      width: 6px;
      height: 6px;
      border: 1px solid $color-blue;
      left: -18px;
      top: 6px;
      border-radius: 50%;
      background: $color-blue;
    }
  }

  &.well {
    padding: 0;

    input[type="radio"],
    input[type="checkbox"] {
      & + label {
        width: 100%;
        display: block;
        padding: 7px 10px;
        padding-left: 30px;
        margin: 0;

        &::before {
          left: 10px;
          top: 12px;
        }
      }

      &:checked + label {
        &::after {
          left: 12px;
          top: 14px;
        }
      }
    }
    input[type="checkbox"] {
      & + label::before {
        border-radius: 0;
        width: 10px;
        height: 10px;
      }
      &:checked + label::before {
        border-radius: 0;
      }
      &:checked + label::after {
        position: absolute;
        content: " ";
        top: 15px !important;
        width: 6px;
        height: 3px;
        border: 0;
        border-left: 2px solid #162e4d;
        border-bottom: 2px solid #162e4d;
        background: transparent;
        border-radius: 0;
      }
    }

    &.active {
      border-color: $color-green;
    }

    &.btnView {
      display: inline-block;
      background: #fff;
    }

    &.noTopBorder {
      border-top: 0;
    }
  }
}

input[type="checkbox"].switch {
  width: 0;
  height: 0;
  opacity: 0;

  & + label {
    position: relative;
    padding-left: 45px;
    cursor: pointer;

    &::before {
      width: 40px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: inline-block;
      border-radius: 12px;

      background-color: #bfc8d3;
      transition: all 0.4s;
    }

    &::after {
      width: 20px;
      height: 20px;
      display: inline-block;
      position: absolute;
      left: 0px;
      top: 0px;
      content: "";
      background: #526783;
      border-radius: 50%;
      transition: all 0.4s;
    }
  }

  &:checked {
    & + label {
      &::before {
        background-color: rgba(22, 179, 175, 0.15);
        border: 1px solid #16b3af;
      }

      &::after {
        left: 20px;
        background-color: #16b3af;
      }
    }
  }
  &:disabled:hover + label {
    cursor: not-allowed !important;
  }
}

input[type="radio"].customRadio {
  width: 0;
  height: 0;
  opacity: 0;

  & + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;

    &::before {
      width: 12px;
      height: 12px;
      position: absolute;
      left: 0;
      top: 4px;
      content: "";
      display: inline-block;
      border-radius: 50%;
      transition: all 0.4s;
      border: 1px solid #142d49;
    }
  }

  &:checked {
    & + label {
      &::before {
        box-shadow: 0 0 0 2px #fff inset;
        background-color: #142d49;
      }
    }
  }

  &.disabled {
    & + label {
      color: $color-blue-lighter;
      cursor: default;
      pointer-events: none;

      &::before {
        border-color: $color-blue-lighter;
        background-color: lighten($color-blue-lighter, 20%);
      }
    }
  }
}
input[type="checkbox"].btn-check-cyan {
  opacity: 0;
  width: 0;
  height: 0;
  & + label {
    border: 1px solid $color-blue;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 0 10px 10px 0;
    cursor: pointer;
    min-width: 100px;
    text-align: center;
  }
  &:checked + label {
    background: $color-cyan;
    color: #fff;
    border-color: $color-cyan;
  }
}
.showPassword {
  padding-left: 5px;
  cursor: pointer;
}

.has-error {
  position: relative;
}

.has-error span {
  font-size: 12px;
  color: $color-red;
  display: block;
}

.has-error .form-control {
  border-color: $color-red;
}

.has-error input + label:before {
  border-color: $color-red;
}

.btn {
  border-radius: 0;
  font-size: 16px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }

  &:focus {
    box-shadow: 0 0 0 0 #97a4b5;
    border: 1px dashed #b4b4b4;
  }

  &.btn-round {
    border-radius: 10px;
  }

  &.btn-xs {
    padding: 0 10px 2px 10px;
  }
  &.btn-lg {
    padding: 12px 32px !important;
    border-radius: 30px !important;
  }

  &.withBorder {
    color: $color-sky;
    background: none;
    border: 1px solid #c0c8d4;
    position: relative;
    font-weight: bold;

    &:hover {
      background: #fff;
    }
  }
}

.btn.bold,
.btn.bold:hover {
  color: #4a90e2;
}

.btn.btn-blue,
.btn-blue:not(:disabled):not(.disabled).active,
.btn-blue:not(:disabled):not(.disabled):active,
.show > .btn-blue.dropdown-toggle {
  background: $color-sky-lightest;
  border-color: $color-sky-lightest;
  border-radius: 30px;
  color: white;
  font-weight: bold;
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn.btn-blue-light,
.btn-blue-light:not(:disabled):not(.disabled).active,
.btn-blue-light:not(:disabled):not(.disabled):active,
.show > .btn-blue-light.dropdown-toggle {
  background: $color-sky-lightest1;
  border-color: $color-sky-lightest1;
  border-radius: 30px;
  color: $color-sky-lightest;
  font-weight: bold;
  padding-left: 3rem;
  padding-right: 3rem;
}

.btn.btn-primary,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: $color-primary;
  border-color: $color-primary;
  border-radius: 30px;
  color: white;
  font-weight: bold;
}

.btn.btn-grey,
.btn-grey:not(:disabled):not(.disabled).active,
.btn-grey:not(:disabled):not(.disabled):active,
.show > .btn-grey.dropdown-toggle {
  background: #c2c2c2;
  border-color: #c2c2c2;
  color: #fff;
}

.btn.btn-grey-dark,
.btn-grey-dark:not(:disabled):not(.disabled).active,
.btn-grey-dark:not(:disabled):not(.disabled):active,
.show > .btn-grey-dark.dropdown-toggle {
  background: $color-blue2;
  border-color: $color-blue2;
  color: #fff;
}

.btn.btn-cyan,
.btn-cyan:not(:disabled):not(.disabled).active,
.btn-cyan:not(:disabled):not(.disabled):active,
.show > .btn-cyan.dropdown-toggle {
  background: $color-cyan;
  border-color: $color-cyan;
  color: #fff;
}

// start sachin doing

.rounded1 {
  border-radius: 4px;
}

.rounded2 {
  border-radius: 8px;
}

.rounded3 {
  border-radius: 12px;
}

.rounded4 {
  border-radius: 16px;
}
.rounded10 {
  border-radius: 40px;
}
.rounded11 {
  border-radius: 44px;
}

.rounded15 {
  border-radius: 60px;
}

.btn.btn-white,
.btn-white:not(:disabled):not(.disabled).active,
.btn-white:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  background: $color-white;
  border-color: $color-white;
  border-radius: 12px;
}
.btn.btn-white-outline,
.btn-white-outline:not(:disabled):not(.disabled).active,
.btn-white-outline:not(:disabled):not(.disabled):active,
.show > .btn-white.dropdown-toggle {
  background: transparent;
  border-color: $color-white;
  border-radius: 12px;
}

.btn.btn-cyan2,
.btn-cyan2:not(:disabled):not(.disabled).active,
.btn-cyan2:not(:disabled):not(.disabled):active,
.show > .btn-cyan2.dropdown-toggle {
  background: $color-cyan;
  border-color: $color-cyan;
  border-radius: 8px;
  color: #fff;
}

.btn.btn-cyan2-outline,
.btn-cyan2-outline:not(:disabled):not(.disabled).active,
.btn-cyan2-outline:not(:disabled):not(.disabled):active,
.show > .btn-cyan2.dropdown-toggle {
  background: transparent;
  border-color: $color-cyan;
  border-radius: 8px;
  color: #fff;
}

.text-cyan {
  color: $color-cyan;
}

.btn.btn-pink,
.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active,
.show > .btn-pink.dropdown-toggle {
  background: $color-pink;
  border-color: $color-pink;
  border-radius: 8px;
  color: #fff;
}

.btn.btn-pink-outline,
.btn-pink-outline:not(:disabled):not(.disabled).active,
.btn-pink-outline:not(:disabled):not(.disabled):active,
.show > .btn-pink.dropdown-toggle {
  background: transparent;
  border-color: $color-pink;
  border-radius: 8px;
  color: #fff;
}

.btn.btn-pinks,
.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active,
.show > .btn-pink.dropdown-toggle {
  background: $color-pink;
  border-color: $color-pink;

  color: #fff;
}

.btn.btn-grey,
.btn-grey:not(:disabled):not(.disabled).active,
.btn-grey:not(:disabled):not(.disabled):active,
.show > .btn-grey.dropdown-toggle {
  background: $color-blue-lighter;
  border-color: $color-blue-lighter;
  border-radius: 8px;
  color: #fff;
}

.btn.btn-grey-outline,
.btn-grey-outline:not(:disabled):not(.disabled).active,
.btn-grey-outline:not(:disabled):not(.disabled):active,
.show > .btn-grey.dropdown-toggle {
  background: $color-blue-lighter;
  border-color: $color-blue-lighter;
  border-radius: 8px;
  color: #fff;
}
.btn.btn-grey1,
.btn-grey1:not(:disabled):not(.disabled).active,
.btn-grey1:not(:disabled):not(.disabled):active,
.show > .btn-grey1.dropdown-toggle {
  background: $color-blue-light;
  border-color: $color-blue-light;
  border-radius: 12px;
  color: #fff;
}

.btn.btn-grey1-outline,
.btn-grey1-outline:not(:disabled):not(.disabled).active,
.btn-grey1-outline:not(:disabled):not(.disabled):active,
.show > .btn-grey1.dropdown-toggle {
  background: $color-blue-light;
  border-color: $color-blue-light;
  border-radius: 12px;
  color: #fff;
}

.btn.btn-grey2-outline,
.btn-grey2-outline:not(:disabled):not(.disabled).active,
.btn-grey2-outline:not(:disabled):not(.disabled):active,
.show > .btn-grey2.dropdown-toggle {
  background: none;
  border-color: $color-blue-light;
  border-radius: 12px;
  color: $color-blue-light;
}

// end, if i add anything new i will put only between start and end

.btn.btn-grey,
.btn-grey:not(:disabled):not(.disabled).active,
.btn-grey:not(:disabled):not(.disabled):active,
.show > .btn-grey.dropdown-toggle {
  background: $color-blue-lighter;
  border-color: $color-blue-lighter;
  color: #fff;
}

.btn.btn-pink,
.btn-pink:not(:disabled):not(.disabled).active,
.btn-pink:not(:disabled):not(.disabled):active,
.show > .btn-pink.dropdown-toggle {
  background: $color-pink;
  border-color: $color-pink;
  color: #fff;
}

.btn.btn-primary-light,
.btn-primary-light:not(:disabled):not(.disabled).active,
.btn-primary-light:not(:disabled):not(.disabled):active,
.show > .btn-primary-light.dropdown-toggle {
  background: $color-primary-lightest-2;
  border-color: $color-primary-lightest-2;
  border-radius: 30px;
  color: $color-primary;
  font-weight: bold;
}

.btn.btn-secondary,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  background: $color-blue;
  border-color: $color-blue;
}

.btn.btn-secondary-light,
.btn-secondary-light:not(:disabled):not(.disabled).active,
.btn-secondary-light:not(:disabled):not(.disabled):active,
.show > .btn-secondary-light.dropdown-toggle,
.btn.btn-lightBlue,
.btn-lightBlue:not(:disabled):not(.disabled).active,
.btn-lightBlue:not(:disabled):not(.disabled):active,
.show > .btn-lightBlue.dropdown-toggle {
  background: #526783;
  border-color: #526783;
  color: #fff;
}

.btn.btn-default,
.btn-default:not(:disabled):not(.disabled).active,
.btn-default:not(:disabled):not(.disabled):active,
.show > .btn-default.dropdown-toggle {
  background: #fff;
  border-color: #fff;
  border-radius: 6px;
  color: $color-blue;
}

.btn.btn-bluebg,
.btn-bluebg:not(:disabled):not(.disabled).active,
.btn-bluebg:not(:disabled):not(.disabled):active,
.show > .btn-bluebg.dropdown-toggle {
  background: #4a90e2;
  color: #fff;
}

.btn-linkView,
.btn-linkView:hover {
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
  color: #4a90e2;
  font-weight: bold;
  cursor: pointer;
}

.btn.btn-addmore,
.btn-addmore:not(:disabled):not(.disabled).active,
.btn-addmore:not(:disabled):not(.disabled):active,
.show > .btn-addmore.dropdown-toggle {
  background: none;
  color: $color-blue;
  border-color: $color-blue;
  padding-left: 35px;
  position: relative;

  &::before {
    position: absolute;
    left: 10px;
    top: 12px;
    content: "aaa";
    width: 16px;
    height: 16px;
    background: url("../assets/images/svg/x.svg") no-repeat;
    font-size: 0;
    transform: rotate(45deg);
  }

  &.location:before {
    background: url("../assets/images/svg/icn-location.svg") no-repeat;
    transform: rotate(0);
  }
}

.btn.btn-default.disabled,
.btn.btn-default:disabled,
.btn.btn-default:disabled:hover {
  background: $color-blue-lighter;
  border-color: $color-blue-lighter;
  color: #dfdfdf;
  opacity: 0.4;
}

.btn:disabled,
.btn:disabled:hover {
  opacity: 0.4 !important;
}

.vl {
  border: 0px;
  border-left: 1px solid $color-blue-lighter;
  background: transparent;
}

.badge-secondary {
  background: $color-blue;
  color: #fff !important;
}

.icons {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  margin-bottom: -4px;
}

.icon12 {
  @extend .icons;
  width: 12px;
  height: 12px;
}

.icon16 {
  @extend .icons;
  width: 16px;
  height: 16px;
}

.icon20 {
  @extend .icons;
  width: 20px;
  height: 20px;
}

.icon24 {
  @extend .icons;
  width: 24px;
  height: 24px;
}
.icon28 {
  @extend .icons;
  width: 28px;
  height: 28px;
}

.icn-file {
  @extend .icons;
  width: 86px;
  height: 64px;
  background: url("../assets/images/listing/listingicon.svg") no-repeat;
  background-size: 100%;

  &.attachment {
    width: 30px;
    height: 30px;
  }
}

.icn-folder {
  @extend .icons;
  width: 86px;
  height: 64px;
  background: url("../assets/images/listing/share&uploadicon.svg") no-repeat;
  background-size: 100%;
}

.icn-nodata {
  @extend .icons;
  width: 100px;
  height: 60px;
  background: url("../assets/images/no-data.png") no-repeat;
  background-size: 100%;
}

.icn-logo {
  @extend .icons;
  width: 46px;
  height: 46px;
  background: url("../assets/images/svg/logo1.svg") no-repeat;
  background-size: 100%;
}

.icn-plus {
  @extend .icon12;
  background: url("../assets/images/svg/x-white.svg") no-repeat;
  margin: 0;
  transform: rotate(45deg);
}

.icn-close {
  @extend .icon16;
  background: url("../assets/images/svg/x.svg") no-repeat;
  margin: 0;

  &.sm {
    width: 10px;
    height: 10px;
    background-size: 100%;
  }
}

.icn-dnarrow {
  @extend .icon16;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat;
  margin: 0;
}

.icn-uparrow {
  @extend .icon16;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat;
  transform: rotate(180deg);
}

.icn-ltarrow {
  @extend .icon16;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat center;
  transform: rotate(90deg);
}

.icn-rtarrow {
  @extend .icon16;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat center;
  transform: rotate(-90deg);
}

.icn-compare {
  @extend .icon20;
  background: url("../assets/images/svg/icn-compare.svg") no-repeat;
  background-size: 100%;
}

.icn-info-lightBlue {
  @extend .icon20;
  background: url("../assets/images/svg/icn-info-light.svg") no-repeat right;
}

.icn-info {
  @extend .icon20;
  background: url("../assets/images/svg/icn-24-px-info.svg") no-repeat right;
}

.icn-user,
.icn-user-white {
  @extend .icon24;
  background: url("../assets/images/svg/user.svg") no-repeat;
}

.icn-search {
  @extend .icon24;
  background: url("../assets/images/svg/magnifying-glass.svg") no-repeat;
}

.icn-cart {
  @extend .icon24;
  background: url("../assets/images/svg/icn-cart.svg") no-repeat;
}

.icn-chat {
  @extend .icon24;
  background: url("../assets/images/svg/chat.svg") no-repeat;
}

.icn-alarm {
  @extend .icon24;
  background: url("../assets/images/svg/alarm.svg") no-repeat;
}

.icn-logout {
  @extend .icon24;
  background: url("../assets/images/svg/logout.svg") no-repeat;
}

.icn-banknote {
  @extend .icon24;
  background: url("../assets/images/svg/banknote.svg") no-repeat;
}

.icn-nego {
  @extend .icon24;
  background: url("../assets/images/svg/icn-nego.svg") no-repeat;
  width: 26px;
}
.icn-buyback {
  @extend .icon24;
  background: url("../assets/images/svg/icn-buyback.svg") no-repeat;
  width: 26px;
}

.icn-radar {
  @extend .icon28;
  background: url("../assets/images/svg/icn-radar.svg") no-repeat;
}
.icn-wallet {
  @extend .icon24;
  background: url("../assets/images/new-svg/icn-wallet.svg") no-repeat;
  width: 26px;
}
.icn-cart-white {
  @extend .icon28;
  background: url("../assets/images/svg/icn-cart-white.svg") no-repeat;
}
.icn-doller-circle {
  @extend .icon28;
  background: url("../assets/images/svg/icn-doller-circle.svg") no-repeat;
}
.icn-dollar-arrow {
  @extend .icon28;
  background: url("../assets/images/svg/icn-dollar-arrow.svg") no-repeat;
}

.font12 {
  .icn-info {
    margin-bottom: -5px;
    margin-left: 5px;
  }
}

.icn-sinfo {
  @extend .icon12;
  background: url("../assets/images/svg/icn-12-px-info-light.svg") no-repeat;
}

.icn-blogo {
  @extend .icon24;
  width: 25px;
  background: url("../assets/images/svg/icn-24-logo-blue.svg") no-repeat;
}

.icn-home {
  @extend .icon24;
  background: url("../assets/images/svg/home.svg") no-repeat;
}

.icn-sign {
  @extend .icon24;
  background: url("../assets/images/svg/sign.svg") no-repeat;
}

.icn-list {
  @extend .icon24;
  background: url("../assets/images/svg/list-1.svg") no-repeat;
}

.icn-banknote {
  @extend .icon24;
  background: url("../assets/images/svg/banknote.svg") no-repeat;
}

.icn-bar-chart {
  @extend .icon24;
  background: url("../assets/images/svg/bar-chart.svg") no-repeat;
}

.icn-box {
  @extend .icon24;
  background: url("../assets/images/svg/box.svg") no-repeat;
}

.icn-pepe {
  @extend .icon24;
  background: url("../assets/images/svg/icn-pepe.svg") no-repeat;
}

.icn-heart {
  @extend .icon24;
  background: url("../assets/images/svg/icn-heart.svg") no-repeat;
}

.icon24-showHide {
  width: 24px;
  height: 24px;
  display: inline-block;
  background: url("../assets/images/svg/icn-hide-show.svg") no-repeat left top;
}

.icon40-listing {
  width: 40px;
  height: 40px;
  display: inline-block;
  background: url("../assets/images/svg/icn-listing-40px.svg") no-repeat left
    top;
}

.icon40-banknote {
  width: 44px;
  height: 40px;
  display: inline-block;
  background: url("../assets/images/svg/icn-banknote-40px.svg") no-repeat left
    top;
}

.icon40-database {
  width: 44px;
  height: 40px;
  display: inline-block;
  background: url("../assets/images/svg/icn-database-40px.svg") no-repeat left
    top;
}

.icon40-price-request {
  width: 44px;
  height: 40px;
  display: inline-block;
  background: url("../assets/images/icn-price-request.png") no-repeat left top;
}

.icon40-buyback {
  width: 44px;
  height: 40px;
  display: inline-block;
  background: url("../assets/images/new-svg/buybacksearch.svg") no-repeat left
    top;
}

.icon40-nego {
  width: 44px;
  height: 40px;
  display: inline-block;
  background: url("../assets/images/svg/icn-nego-40px.svg") no-repeat left top;
}

.icn-upload {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: url("../assets/images/svg/icn-service-up.svg") no-repeat left top;
  background-size: 100% 100%;
}
.icn-upload-withIcon {
  width: 80px;
  height: 80px;
  display: inline-block;
  background: url("../assets/images/svg/icn-service-upload.svg") no-repeat left
    top;
  background-size: 100% 100%;
}

.icon24-showHide.hidepassword {
  background: url("../assets/images/svg/icn-hide.svg") no-repeat left top;
}

.icn-updnarrow {
  width: 16px;
  height: 22px;
  position: relative;

  &::after {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0px;
    content: "";
    background: url("../assets/images/svg/icn-dropdown.svg") no-repeat;
    transform: rotate(180deg);
  }

  &::before {
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    bottom: 0px;
    content: "";
    background: url("../assets/images/svg/icn-dropdown.svg") no-repeat;
    transform: rotate(360deg);
  }
}

.alert-blue {
  background: #526783;
  border: 1px solid #fff;
  border-radius: 0;
  padding: 6px;
}

.alert-red {
  background: rgba(239, 175, 69, 0.1);
  border: 1px solid $color-primary-light;
  border-radius: 0;
  padding: 2px 6px;
}

.position-relative {
  position: relative;
}

/* Cursor css */
.pointer-cursor {
  cursor: pointer;
}

/* Modal CSS */
.modal-dialog {
  max-width: 600px;
}

.modal-backdrop {
  background: $color-blue;
  opacity: 0.8 !important;
}

.modal-content {
  border-radius: 16px;
}

.modal-body {
  padding: 40px;
  font-size: 14px;

  .close {
    margin-top: -20px;
    margin-right: -15px;
  }

  .form-control.location,
  .form-control.location:focus {
    background: url("../assets/images/icn-placeholder-24-x-24.png") no-repeat
      right;
  }

  &.v2 {
    padding: 20px;

    .heading {
      border-bottom: 1px solid #162e4d;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
      font-size: 20px;
      line-height: 24px;

      &.bold {
        border-bottom-width: 2px;
      }

      .bold {
        font-weight: 400 !important;
      }
    }

    .close {
      width: 21px;
      height: 21px;
      display: block;
      position: absolute;
      right: 20px;
      top: 30px;

      &::before,
      &::after {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-bottom: 1px solid #162e4d;
        border-right: 1px solid #162e4d;
        transform: rotate(45deg);
        content: "";
        top: 0;
        right: 15px;
        position: absolute;
      }

      &::after {
        transform: rotate(-135deg);
        top: 15px;
      }
    }
  }
}

.nav-tabs {
  padding: 0;
  margin: 0;
  border-color: $color-blue-lightest;
  font-weight: bold;

  .nav-link {
    // background: $color-lightbg;
    color: $color-blue-lighter;
    padding: 10px 16px;
    border-bottom: 1px solid $color-blue-lightest;
    border: 0;
    border-radius: 0;

    &:hover {
      color: $color-blue;
    }

    &.active {
      background: #fff !important;
      color: $color-blue !important;
      // border: 1px solid $color-blue-lightest;
      border-bottom: 2px solid $color-blue;
    }
  }
}

.nav-round {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    display: inline-block;
    margin-bottom: 10px;

    a {
      display: inline-block;
      border-radius: 20px;
      padding: 4px 20px;
      border: 1px solid $color-blue-lightest;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 15px;
      color: $color-blue-lightest;
      position: relative;

      &:hover {
        background-color: $color-blue-lightest;
        border-color: $color-blue-lightest;
        text-decoration: none;
        color: #fff;
      }

      &.active {
        background-color: $color-blue;
        border-color: $color-blue;
        color: #fff;

        &::before {
          content: "";
          position: absolute;
          left: calc(50% - 6px);
          top: 100%;
          border-top: 6px solid $color-blue;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
        }
      }
    }
  }
}

.minWidth {
  .withLeftBorder ul {
    border: 0;

    li:first-child {
      position: relative;

      &::before {
        position: absolute;
        left: -30px;
        bottom: 0;
        width: 30px;
        height: 1px;
        background: #c0c8d4;
        content: "";
      }
    }

    li {
      width: 31%;
      max-width: 250px;
    }
  }
}

.minWidth,
.minWidth ul {
  margin-left: -30px;
  padding-left: 30px;

  .nav-item {
    min-width: 140px;
  }
}

.tab-content {
  border: 1px solid $color-blue-lightest;
  border-top: 0;
}

.defaultAccountPopup {
  width: 668px;
  max-width: 668px;
}

.account {
  border: 1px solid $color-blue;
  font-size: $font-size;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 0;

  .row {
    min-height: 40px;
  }
}

.popover {
  font-size: 12px;
  line-height: 16px;

  .arrow {
    margin: 0;
  }

  &.uploadPopover {
    max-width: 430px !important;
  }

  &.discountPopover {
    max-width: 530px !important;
  }
  &.RALColor {
    // max-width: 304px !important;
    min-width: 350px !important;
  }
  &.orange {
    background: #f8861c;
    font-size: 16px;
    border-color: #f8861c;
    color: #ffffff;
    border-radius: 0;

    .arrow::before,
    .arrow::after {
      border-right-color: #f8861c;
    }

    .popover-body {
      color: #ffffff;
    }
  }

  &.blue {
    background: #4a90e2;
    font-size: 16px;
    border-color: #4a90e2;
    color: #ffffff;
    border-radius: 0;

    .arrow::before,
    .arrow::after {
      border-right-color: #4a90e2;
    }

    .popover-body {
      color: #ffffff;
    }
  }
}

.toast-container {
  // box-shadow: 0 0 10px $color-lightbg;
  padding: 0.75rem 1.25rem 0.75rem 60px !important ;
  .ngx-toastr {
    background: #fff !important;
    display: block;
    padding: 10px !important;
    // border: 1px solid #b4b4b4;
    border-radius: 16px !important;
    font-size: 16px !important;
    color: #162e4d !important;
    margin-left: auto !important;
    box-shadow: 0 0 10px $color-lightbg;
    min-width: max-content !important;
    width: none !important;

    .toast-title {
      padding: 5px;
    }

    .toast-message {
      display: block !important;
      color: black !important;
      padding: 2px 2px;
    }

    &.toast-info {
      background: #fff url("../assets/images/svg/new-done-tick.svg") no-repeat
        10px !important;
      padding-left: 60px !important;
      min-width: fit-content;
      .toast-title {
        color: #678cfc;
        background-color: #678cfc26;
        padding: 4px;
        border-radius: 4px;
      }
    }

    &.toast-success {
      padding-left: 60px !important;
      position: relative;
      background: #fff url("../assets/images/svg/new-success-tick.svg")
        no-repeat 10px !important;
      .toast-title {
        color: #14b23d;
        background-color: #14b23d26;
        padding: 4px;
        border-radius: 4px;
      }
      // &::before {
      //   position: absolute;
      //   left: 10px;
      //   top: 10px;
      //   width: 20px;
      //   height: 20px;
      //   // background: url("../assets/images/svg/oval-green.svg") no-repeat;
      //   // content: " ";
      // }

      // &::after {
      //   position: absolute;
      //   left: 10px;
      //   top: 10px;
      //   width: 20px;
      //   height: 20px;
      //   // background: url("../assets/images/svg/icn-tick.svg") no-repeat center
      //   //   center;
      //   // content: " ";
      // }
    }

    // &.toast-success {
    //   background: #fff url("../assets/images/svg/new-success-tick.svg")
    //     no-repeat 10px !important;
    //   padding-left: 60px !important;
    //   min-width: fit-content;
    // }

    &.toast-error {
      padding-left: 40px !important;
      position: relative;

      &::before {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        background: url("../assets/images/svg/oval-red.svg") no-repeat;
        content: " ";
      }

      &::after {
        position: absolute;
        left: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
        content: "x";
        text-align: center;
        color: #fff;
        font-size: 19px;
        line-height: 16px;
      }
    }

    &.toast-warning {
      background: #fff url("../assets/images/svg/icn-warning.svg") no-repeat
        10px !important;
      padding-left: 40px !important;
    }

    .toast-title {
      display: inline-block;
      margin-right: 10px;
      font-weight: bold;
    }

    .toast-message {
      display: inline-block;
      white-space: nowrap;
    }

    .toast-close-button {
      span {
        // display: none;
        opacity: 0;
      }
      opacity: 1 !important;
      background: url("../assets/images/svg/new-x-icon.svg") no-repeat !important;
      background-size: contain !important;
      width: 20px;
      height: 20px;
      // display: inline-block;
      top: 0 !important;
      right: 0 !important;
    }
  }
}

/* Some Extra Required CSS*/
.well {
  background: #2e445f;
  padding: 14px;
  border: 1px solid #b4b4b4;

  &.noBorder {
    border: 0;
  }

  &.lg {
    padding: 20px;
  }
}

.bordered {
  border: 1px solid #979797;
  padding: 20px;
}

.stars {
  width: 50px;
  display: inline-block;
  background: url(../assets/images/svg/star.svg) repeat-x;
  height: 10px;
  line-height: 12px;
  position: relative;

  span {
    display: inline-block;
    background: url(../assets/images/svg/star-active.svg) repeat-x;
    font-size: 0px;
    height: 10px;
    position: absolute;
    left: 0;
    top: 0;

    &.rating1 {
      width: 10px;
    }

    &.rating2 {
      width: 20px;
    }

    &.rating3 {
      width: 30px;
    }

    &.rating4 {
      width: 40px;
    }

    &.rating5 {
      width: 50px;
    }
  }
}

.stars-bordered {
  width: 70px;
  background: url(../assets/images/svg/star14-border.svg) repeat-x;
  height: 14px;
  display: inline-block;
  line-height: 14px;
  position: relative;
  margin-bottom: -3px;

  span {
    display: inline-block;
    background: url(../assets/images/svg/star14.svg) repeat-x;
    font-size: 0px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 0;

    &.rating1 {
      width: 14px;
    }

    &.rating2 {
      width: 28px;
    }

    &.rating3 {
      width: 42px;
    }

    &.rating4 {
      width: 56px;
    }

    &.rating5 {
      width: 70px;
    }
  }

  &.lg {
    background: url(../assets/images/svg/star30-border.svg) repeat-x;
    height: 30px;
    width: 200px;

    span {
      background: url(../assets/images/svg/star30.svg) repeat-x;
      height: 30px;

      &.rating1 {
        width: 40px;
      }

      &.rating2 {
        width: 80px;
      }

      &.rating3 {
        width: 120px;
      }

      &.rating4 {
        width: 160px;
      }

      &.rating5 {
        width: 200px;
      }
    }
  }
}
.flagChoice {
  background: url("../assets/images/svg/choice.svg") no-repeat left top;
  width: 125px;
  height: 21px;
  display: inline-block;
  color: #fff;
  font-size: 12px;
  padding: 1px 6px;
  position: relative;
  left: -16px;
}
.text-color {
  color: $color-blue;
}

.text-blue {
  color: $color-sky;
}

.text-sky-light {
  color: $color-sky-light;
}

.text-orange {
  color: $color-primary;
}
.text-orange-1 {
  color: $color-primary-1;
}

.text-red-1 {
  color: $color-red-1;
}

.text-red-3 {
  color: $color-red-3;
}
.text-grey-light {
  color: $color-grey-light;
}

.text-blue-light {
  color: $color-blue-light;
}
.text-blue-light-1 {
  color: $color-blue-light-1;
}

.text-blue-lighter {
  color: $color-blue-lighter;
}

.text-blue-lightest2 {
  color: $color-blue-lightest-2;
}
.text-blue-lightest5 {
  color: $color-blue-lghtest-5;
}
.text-blue-lightest10 {
  color: $color-blue-lightest-10;
}
.text-dark-green {
  color: $color-dark-green;
}

.text-grey-dark {
  color: $color-grey-dark;
}
.text-blue-dark {
  color: $color-blue-dark;
}
.text-color-pink {
  color: $color-pink;
}

.text-sky-lightest {
  color: $color-sky-lightest;
}

.text-sky-lightest3 {
  color: $color-sky-lightest3;
}

.top {
  top: 0;
}
.right {
  right: 0;
}
.left {
  left: 0;
}
.btm {
  bottom: 0;
}

.text-red,
.text-red:hover {
  color: $color-red;
}

.text-light-blue {
  color: $color-blue-light;
}

.text-grey-blue {
  color: $color-blue-lighter !important;
}

.text-light-blue2 {
  color: $color-blue-lightest !important;
}

.text-grey {
  color: $color-grey;
}

.text-green {
  color: $color-green;
}
.text-green-lighter {
  color: $color-green-lighter;
}
.text-green-lightest {
  color: $color-green-lightest;
}

.text-darkest-green {
  color: $color-darkest-green;
}

.text-black {
  color: #000000;
}

.text-cyan {
  color: $color-cyan;
}

.text-light-blue-grey {
  color: #c0c8d4;
}

.text-pink {
  color: #de1382;
}

.text-white {
  color: #fff;
}

.text-blured {
  text-shadow: 0 0 20px #fff;
  color: transparent !important;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome and Opera */
}

.text-blured-red {
  display: inline-block;
  background: url(../assets/images/red-shade.png) no-repeat;
  background-size: 100% 100%;
  font-size: 0 !important;
  padding: 0;
  margin: 0;

  & + .text-blured-red {
    width: 0;
    height: 0;
  }

  // text-shadow: 0 0 20px #ff5151;
  // color: transparent !important;
  // -webkit-touch-callout: none;
  // __<<ngThemingMigrationEscapedComment21>>__
  // -webkit-user-select: none;
  // __<<ngThemingMigrationEscapedComment22>>__
  // -khtml-user-select: none;
  // __<<ngThemingMigrationEscapedComment23>>__
  // -moz-user-select: none;
  // __<<ngThemingMigrationEscapedComment24>>__
  // -ms-user-select: none;
  // __<<ngThemingMigrationEscapedComment25>>__
  // user-select: none;
  // __<<ngThemingMigrationEscapedComment26>>__
  //   display: inline-block;
  //   position: relative;
}

.text-uppercase {
  text-transform: uppercase;
}

.strike {
  text-decoration: none;
  position: relative;
  text-decoration: line-through;
}

.strike-blue {
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    border-bottom: 1px solid $color-blue;
    left: 0;
    bottom: 35%;
  }
}

.lineHeight10 {
  line-height: 10px;
}

.lineHeightfor12 {
  display: block;
  line-height: 14px;
}

.lineHeightfor16 {
  display: block;
  line-height: 20px;
}
.lineHeight19 {
  line-height: 19px;
}

.lineHeight24 {
  line-height: 24px;
}
.lineHeight42 {
  line-height: 42px;
}

small,
.small {
  font-size: 12px;
}

.font8 {
  font-size: 8px !important;
}

.font9 {
  font-size: 9px !important;
}

.font10 {
  font-size: 10px !important;
}

.font11 {
  font-size: 11px !important;
}

.font12 {
  font-size: 12px !important;
}

.font13 {
  font-size: 13px !important;
}

.font14 {
  font-size: 14px;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px !important;

  .font24 {
    font-size: 16px;
    line-height: 20px;
  }

  .font16 {
    font-style: 14px !important;
  }
}

.font15 {
  font-size: 15px !important;
}

.font16 {
  font-size: $font-size;

  .font24Imp,
  .font24 {
    font-size: 16px !important;
  }

  .font16Imp,
  .font14,
  .font16 {
    font-size: 13px !important;
  }
}

.font16Only {
  [class^="font"] {
    font-size: 16px !important;
  }
}

.font15Only {
  [class^="font"] {
    font-size: 15px !important;
  }
}

.font17 {
  font-size: 17px;
}

.font18 {
  font-size: 18px;
}

.font20 {
  font-size: 20px;
}

.font24 {
  font-size: 24px;
  line-height: 30px;
}
.font28 {
  font-size: 28px;
}
.font30 {
  font-size: 30px;
}

.font32 {
  font-size: 32px;
  line-height: 32px;
}
.font-md-26 {
  @include media-breakpoint-down(md) {
    font-size: 26px !important;
  }
}

.font35 {
  font-size: 35px;
}

.font36 {
  font-size: 36px;
  line-height: 40px;
}
.font40 {
  font-size: 40px;

  @media (max-width: 600px) {
    font-size: 28px;
  }
}

.font50 {
  font-size: 50px;
}
.font80 {
  font-size: 80px;
}
.font-md-32 {
  @include media-breakpoint-down(md) {
    font-size: 32px;
  }
}

.font-md-40 {
  @include media-breakpoint-down(md) {
    font-size: 40px;
  }
}

.font60 {
  font-size: 60px;
  font-weight: normal;
  @media (max-width: 600px) {
    font-size: 35px;
  }
}

.font24Imp {
  font-size: 24px !important;
}

.font16Imp {
  font-size: $font-size !important;
}

.font12 .font16,
.font12 .font24 {
  font-size: 12px;
}

.font-freightSans-pro {
  font-family: freight-sans-pro, sans-serif !important;
}

.fs-italic {
  font-style: italic;
}

.font-lato {
  font-family: "Lato", Arial, Helvetica, sans-serif;
}

.fw-350 {
  font-weight: 350;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
.ls-3 {
  letter-spacing: 3.5px;
}
.height24 {
  min-height: 24px;
}

.height36 {
  min-height: 36px;
}

// .height72 {
//   min-height: 72px;
// }

.color-white {
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
  }
}

.clear {
  clear: both;
}

.nobold {
  font-weight: normal !important;
}

.bold {
  font-weight: 700 !important;
}

.opacity50 {
  opacity: 0.5;
}

.rotateRight {
  transform: rotate(-90deg);
}

.rotateLeft {
  transform: rotate(90deg);
}

.rotateUp {
  transform: rotate(180deg);
}

.imgalign {
  margin-top: -4px;
  margin-right: 4px;
}

.imgInfo {
  background: $color-blue;
  color: #fff;
  text-align: center;

  img {
    margin-top: -2px;
  }
}

.curserDefault {
  cursor: default !important;
}

.curserPointer {
  cursor: pointer !important;
}

.wordWrap {
  word-wrap: break-word !important;
  max-width: 300px;
}

.textWrap {
  word-wrap: break-word;
}

.row div[class^="col-"] {
  word-wrap: break-word;
}

.row div[class^="col-"][data-thead] {
  position: relative;
  padding-top: 30px;
  padding-bottom: 20px;

  &::before {
    position: absolute;
    content: attr(data-thead);
    left: 0;
    top: 0;
    font-size: 14px;
    font-weight: normal;
    color: #b5bfcb;
    width: 100%;
  }
}

.inlineBlock {
  display: inline-block !important;
}
.widthAuto {
  width: auto !important;
}
.bg-green {
  background-color: $color-green;
}
.bg-darkest-green {
  background-color: $color-darkest-green;
}
.bg-green-lighter {
  background-color: $color-green-lighter;
}

.bg-dark-green {
  background-color: $color-dark-green;
}
.bg-white {
  background-color: $color-white;
}
.bg-off-white {
  background-color: $color-off-white;
}
.bg-sky-lightest {
  background-color: #678cfc;
}

.bg-sky-lightest1 {
  background-color: $color-sky-lightest1;
}
.bg-sky-lightest2 {
  background-color: $color-sky-lightest2;
}

.bg-blue-dark-2 {
  background-color: $color-blue-dark-2;
}
.release-simple-bg {
  background-image: url("../assets/images/bgreleasesimple.svg");
  background-size: 80% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $color-blue-lightest-2;
  min-height: 600px;
  position: relative;

  @include media-breakpoint-down(md) {
    background-size: 100%;
  }
}
.simple-bg {
  background-image: url("../assets/images/new-svg/simplebg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // background-color: $color-blue-lightest-2;
  min-height: 600px;
  position: relative;
}

.variety-payment-bg {
  background-image: url("../assets/images/bgvarietypayment.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  // background-color: $color-blue-lightest-2;
  // min-height: 600px;
  position: relative;

  @include media-breakpoint-down(md) {
    background-size: 100%;
  }
}
.faster-release-bg {
  background-image: url("../assets/images/bgfasterrelease.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  // background-color: $color-blue-lightest-2;
  // min-height: 600px;
  position: relative;

  @include media-breakpoint-down(md) {
    background-size: 100%;
  }
}
.account-mgmt-bg {
  background-image: url("../assets/images/account-management/accountmgmtbg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  @include media-breakpoint-down(md) {
    background-size: 100%;
  }
}
.border {
  border: 1px solid;
}
.border-blue {
  @extend .border;
  border-color: $color-blue !important;
}
.border-cyan {
  @extend .border;
  border-color: $color-cyan;
}
.border-pink {
  @extend .border;
  border-color: $color-pink;
}
.border-blue-light {
  @extend .border;
  border-color: $color-blue-light;
}

.bg-orange {
  background-color: $color-primary;
}

.bg-blue-lightest {
  background-color: $color-sky-lightest;
}

.bg-red {
  background-color: $color-red;
}
.bg-red-2 {
  background-color: $color-red-2;
}
.bg-cyan {
  background-color: $color-cyan !important;
}
.bg-cyan-lighter {
  background-color: rgba(22, 179, 175, 0.15);
}
.bg-cyan-lightest {
  background-color: rgba(22, 179, 175, 0.6);
}
.bg-dark-blue {
  background-color: $color-dark-blue !important;
}
.bg-dark-cyan {
  background-color: $color-dark-cyan !important;
}
.bg-dark-pink {
  background-color: $color-dark-pink !important;
}
.bg-pink {
  background-color: $color-pink;
}
.bg-primary-lightest-1 {
  background-color: $color-primary-lightest-1;
}
.bg-primary-lightest-2 {
  background-color: $color-primary-lightest-2;
}
.bg-blue {
  background-color: $color-blue;
}
.bg-cyan-1 {
  background-color: $color-cyan-1;
}
.bg-pink-1 {
  background-color: $color-pink-1;
}
.bg-blue-light {
  background-color: $color-blue-light;
}
.bg-blue-lighter {
  background-color: $color-blue-lighter;
}
.bg-blue-lightest-2 {
  background-color: $color-blue-lightest-2;
}
.bg-blue-lightest-4 {
  background-color: $color-blue-lightest-4;
}
.bg-color-blue-lghtest-5 {
  background-color: $color-blue-lghtest-5;
}
.bg-color-blue-lghtest-6 {
  background-color: $color-blue-lghtest-6;
}
.bg-color-blue-lghtest-7 {
  background-color: $color-blue-lghtest-7;
}
.bg-color-blue-lightest-8 {
  background-color: $color-blue-lightest-8;
}
.bg-blue-dark-1 {
  background-color: $color-blue-dark-1;
}
.bg-blue-grey {
  background-color: $color-blue-grey;
}
.bg-blue-lightest-9 {
  background-color: $color-blue-lightest-9;
}
.bg-blue-lightest-10 {
  background-color: $color-blue-lightest-10;
}
.bg-yellow-light {
  background-color: $color-yellow-light;
}
.bg-lightbg {
  background-color: $color-lightbg;
}

.bg-yellow-lightest {
  background-color: $color-yellow-lightest;
}
.bg-priamry-lightest {
  background-color: $color-primary-lightest;
}

.bg-primary-lightest-3 {
  background-color: $color-primary-lightest-3;
}

.bg-spot {
  background: url("../assets/images/new-svg/backendcontainer.svg") no-repeat
    center center;
  background-size: cover;
}
.bg-buy {
  background: url("../assets/images/new-svg/buybg.svg") no-repeat center center;
  background-size: cover;
}

.subListing {
  padding: 0;
  margin: 0;
  margin-left: 15px;
  list-style-type: none;
  position: relative;
  // &::before{
  //   width: 1px;
  //   height: 100%;
  //   content: '';
  //   background: #bfc8d3;
  //   position: absolute;
  //   left: 0px;
  //   top: -10px;
  // }
  li {
    display: block;
    margin-top: 10px;
    margin-left: 50px;
    position: relative;
    &::before {
      width: 50px;
      height: calc(100% + 10px);
      content: "";
      background: #fff;
      position: absolute;
      left: -50px;
      top: -50%;
      border-left: 1px solid #bfc8d3;
      border-bottom: 1px solid #bfc8d3;
      margin-top: -10px;
    }
    &:first-child {
      &::before {
        height: calc(50% + 10px);
        top: 0px;
      }
    }
  }
}
.actiondn {
  position: relative;
  text-align: right;

  .btn {
    background: none;
    border: 1px solid $color-blue-lightest;
    padding-right: 30px;
    position: relative;
    min-width: 90px;

    &:after {
      position: absolute;
      content: "";
      top: calc(50% - 1px);
      right: 10px;
      border-top: 6px solid $color-sky;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 110px;
    z-index: 9;
    background: #fff;
    border: 1px solid $color-blue-lightest;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    display: none;

    li {
      list-style-type: none;
      padding: 0;
      margin: 0;
      position: relative;

      ul {
        right: 100%;
        top: 0;
        display: none;
      }

      a {
        display: block;
        padding: 5px 10px;
        text-align: left;
        color: $color-blue;

        &:hover {
          background: $color-blue-lighter;
          text-decoration: none;
        }
      }
    }
  }

  &:hover {
    ul {
      display: block;

      ul {
        display: none;
      }

      li:hover {
        ul {
          display: block;
        }
      }
    }
  }
}

.table .actiondn ul {
  min-width: auto !important;
  width: max-content;

  li a {
    text-align: left;
  }
}

*[container="body"] {
  cursor: pointer;
}

.rightSection {
  width: calc(100% - 180px);
  margin-left: 30px;
  padding-bottom: 55px;
  margin-top: 20px;
}

.showDiv {
  width: 55px;
  float: right;
  position: fixed;
  top: 64px;
  right: 0;
  height: calc(100vh - 64px);
  z-index: 999;
  background-color: $color-blue-lighter;

  .btn,
  .btn:hover {
    position: relative;
    color: #678cfc;
    padding: 0;
    display: block;
    text-align: center;
    margin-top: 35vh;
  }

  .btn:after {
    position: absolute;
    top: 35px;
    left: 45%;
    content: "";
    width: 16px;
    height: 16px;
    border: 1px solid #fff;
    border-top: 0;
    border-right: 0;
    transform: rotate(45deg);
  }
}

.sideSection {
  width: 250px;
  float: right;
  background-image: linear-gradient(to top, $color-blue, #526783);
  padding: 0px;
  vertical-align: top;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
  position: fixed;
  right: 0;
  top: 64px;
  z-index: 0;

  .scrollDiv {
    max-height: 80vh;
    overflow-y: auto;

    .defaultBankAccountDTO {
      padding: 5px 0;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;

    th,
    td {
      padding: 5px 0px 5px 10px;
      margin: 0;
      vertical-align: top;
      font-weight: 300;
    }

    th {
      font-weight: 700;
      width: 47%;
    }
  }
}

.sideSection .scrollDiv::-webkit-scrollbar {
  width: 12px;
}

.sideSection .scrollDiv::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background: #f2f2f2;
  border-radius: 6px;
}

.sideSection .scrollDiv::-webkit-scrollbar-thumb {
  background-color: #b4b4b4;
  outline: 1px solid slategrey;
  border-radius: 6px;
  margin: 1px;
}

hr.light {
  border-color: #526783;
}

hr.dark {
  border-color: $color-blue;
}

hr.bold {
  border-top-width: 5px;
}

.borderRight {
  border-right: 1px solid #526783;
  display: inline-block;
}

.borderLeftRight {
  border-right: 1px solid #526783;
  border-left: 1px solid #526783;
}

.crossDelete {
  background: url("../assets/images/svg/x.svg") no-repeat left top;
  width: 24px;
  height: 24px;
  display: inline-block;
  font-size: 0;
  cursor: pointer;
}

.colorCode10 {
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #fff;
  margin-bottom: -2px;
}

.colorCode14 {
  width: 14px;
  height: 14px;
  display: inline-block;
  background: #fff;
  margin-bottom: -2px;
}

//1 line css end

.borderLR {
  position: relative;
  text-align: center;
  background: none;
}

.borderLR:before,
.borderLR:after {
  position: absolute;
  content: " ";
  border-bottom: 1px solid $color-blue;
  width: 20%;
  left: 0;
  top: 10px;
}

.borderLR:after {
  left: auto;
  right: 0;
}

.borderLR.light {
  position: relative;
  text-align: center;
  background: none;
}

.borderLR.light:before,
.borderLR.light:after {
  border-color: $color-blue-lighter;
}

.borderLR.width50px:before,
.borderLR.width50px:after {
  width: calc(50% - 25px);
}

.draganddrop {
  display: block;
  border: 1px dashed #526783;
  text-align: center;

  input {
    width: 0;
    opacity: 0;
  }

  .ngx-file-drop__drop-zone {
    border: 0 !important;
    border-radius: 0 !important;
    height: auto !important;
  }

  .ngx-file-drop__content {
    display: block !important;
    color: $color-blue !important;
    min-height: 180px;
    padding: 17px;
    height: auto !important;
  }
  &.whiteBg {
    background: #fff !important;
    border: 1px solid #cad1da;
    .ngx-file-drop__content {
      min-height: auto;
      padding-bottom: 0;
    }
  }
}

.bar {
  background: $color-blue-lightest;
  margin: 10px 0 0 0;

  span {
    width: 60%;
    background: #4ee17b;
    display: block;
    font-size: 0;
    height: 2px;
    position: relative;

    &.full {
      width: 100%;
    }

    &.error {
      background: $color-red;
    }
  }
}

.bar span::before {
  position: absolute;
  right: 0;
  top: 5px;
  content: attr(data-info);
  color: #fff;
  font-size: $font-size;
  margin-right: -20px;
}

.filter {
  select {
    background: none;
    color: #fff;
    font-size: 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-overflow: "";
    border: 1px solid #fff;
    padding: 0px 2px;
    margin-right: 5px;

    option {
      background: #fff;
      color: $color-blue;
    }

    &::-ms-expand {
      -webkit-appearance: none;
      -moz-appearance: none;
      text-indent: 1px;
      text-overflow: "";
    }

    &::-ms-clear {
      display: none;
    }
  }
}

.applyFilter {
  font-size: 14px;
}

.applyFilter table {
  width: 100%;
}

.applyFilter td:first-child {
  padding-right: 10px;
}

.applyFilter .btn,
.applyFilter select {
  font-size: 14px;
  padding: 0 5px;
}

.listing {
  margin-bottom: 8px;

  .caption {
    width: 100%;
    display: block;
    caption-side: top;
    color: $color-blue;
    font-weight: bold;
    padding-top: 0;
    font-size: 24px;
    padding-bottom: 20px;
  }

  table {
    width: 100%;
    margin: 0;

    tr:nth-child(1) th {
      height: 45px;
    }

    tr:nth-child(2) th {
      height: 36px;
    }

    th {
      background: $color-blue-lightest;
      padding: 2px 10px;
      vertical-align: middle;
      line-height: 16px;
    }

    td {
      padding: 10px 5px;
    }

    .font12 {
      line-height: 14px;

      .icn-info {
        width: 12px;
        background-size: 100%;
      }
    }

    .discount {
      font-size: 12px;
      line-height: 14px;
      margin-bottom: 5px;

      th,
      td {
        background: none;
        padding: 2px;
        padding-left: 0;
        border: 0;
        height: auto;

        &:last-child {
          padding-right: 0;
          text-align: right;
        }
      }
    }
  }
}

.listing table td {
  padding: 10px;
}

.listing table img.width12 {
  width: 12px;
}

.tableOnBg {
  width: 100%;

  th {
    background: #2e4462;
    padding: 7px 10px;
    line-height: 18px;

    img {
      float: right;
      margin-top: 4px;

      &.width12 {
        width: 12px;
      }
    }

    &.width200 {
      width: 200px;
    }

    &.width120 {
      width: 120px;
    }
  }

  td {
    padding: 10px;
    vertical-align: top;
    border-bottom: 1px solid #2e4462;
  }
}

.spriptedBorder {
  width: calc(100% - 2px);

  thead {
    th {
      border: 0;
      font-weight: normal;
      padding: 10px;

      &:nth-child(8) {
        width: 10%;
      }

      &:nth-child(10) {
        width: 10%;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 10px;
      }

      &:nth-of-type(odd) td {
        background: $color-lightbg;
        border-bottom: 1px solid $color-blue-lightest;
        border-top: 1px solid $color-blue-lightest;

        &:first-child {
          border-left: 1px solid $color-blue-lightest;
        }

        &:last-child {
          border-right: 1px solid $color-blue-lightest;
        }
      }
    }
  }
}

.table {
  color: $color-blue;

  thead.withBg {
    // background: $color-lightbg;

    th {
      border-bottom: 0;
      // border-color: $color-blue-lightest;

      // &:first-child {
      //   border-left: 1px solid $color-blue-lightest;
      // }

      // &:last-child {
      //   border-right: 1px solid $color-blue-lightest;
      // }
    }
  }

  td {
    border-color: $color-blue-lightest;
  }

  &.borderedBody {
    border-collapse: separate;
    border-spacing: 0 8px;

    th {
      border: 0;
      padding-bottom: 0;
      vertical-align: middle;
    }

    tbody {
      tr td {
        border: 1px solid $color-blue-lightest;
        background: $color-lightbg;
        border-left: 0;
        border-right: 0;

        &:first-child {
          border-left: 1px solid $color-blue-lightest;
        }

        &:last-child {
          border-right: 1px solid $color-blue-lightest;
        }
      }
    }
  }

  th {
    &.sort,
    &.sortACN,
    &.sortDCN {
      span {
        position: relative;
        cursor: pointer;
        padding-right: 15px;
        overflow: hidden;
        display: inline-block;
        margin-bottom: -4px;

        &::after,
        &::before {
          position: absolute;
          width: 15px;
          height: 15px;
          right: 0;
          content: "";
        }

        &::after {
          background: url("../assets/images/svg/icn-dropdown.svg") no-repeat;
          transform: rotate(180deg);
          background-size: contain;
        }

        &::before {
          background: url("../assets/images/svg/icn-dropdown.svg") no-repeat;
          transform: rotate(0deg);
          background-size: contain;
        }
      }
    }

    &.sort {
      span {
        &::after {
          top: calc(50% - 5px);
        }

        &::before {
          bottom: calc(50% - 11px);
          right: -5px;
        }
      }
    }

    &.sortACN {
      span {
        &::before {
          top: 50%;
          transform: translate(0, -50%) rotate(180deg);
          background-position: center;
        }

        &::after {
          bottom: -100%;
        }
      }
    }

    &.sortDCN {
      span {
        &::after {
          top: 50%;
          transform: translate(0, -50%);
          background-position: center;
        }

        &::before {
          bottom: -100%;
        }
      }
    }
  }

  &.table-sm {
    td {
      padding: 10px 5px;
    }

    th,
    td {
      &:first-child {
        padding-left: 10px;
      }

      &:last-child {
        padding-right: 10px;
      }
    }
  }
}

table.noBorder {
  td,
  th {
    border: 0;
  }
}

.productListing {
  border: 1px solid #2e445f;
  transition: all 0.5s;

  &:hover {
    border-color: #4ee17b;
  }

  .row .col-md-7 {
    padding-left: 0;
  }

  .text-red {
    position: relative;
    display: inline-block;

    & .text-blured-red {
      position: absolute;
      left: 0;
      top: 0;
    }
  }
}

.specialEquipment {
  .titleInfo {
    top: -20px;
  }

  span.value {
    border: 1px solid #526783;
    display: inline-block;
    padding: 2px 5px;
    margin: 7px 5px 0px 0px;
    font-size: 14px;

    .cross {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}

#searchFilter {
  padding-right: 5px;

  .well {
    padding: 20px 26px;
    background: $color-blue-light;
    border: 1px solid $color-blue-lighter;

    .form-control {
      color: #fff;
      font-size: 12px;
      border-color: #fff;
      background: none;

      &::placeholder {
        color: #fff;
        font-size: 12px;
      }

      &.location {
        background: url("../assets/images/icn-placeholder.png") no-repeat right;
        background-size: 16px;
        padding-right: 20px;
      }
    }
  }

  label {
    position: relative;
    font-size: 12px;
    display: block;
    margin: 0;
  }

  ul {
    padding: 6px;
    margin: 0;
    margin-top: 6px;
    list-style-type: none;
    border: 1px solid $color-blue-lightest;
    max-height: 175px;
    overflow-y: auto;

    li {
      position: relative;

      input[type="checkbox"] {
        opacity: 0;
        position: absolute;

        & + label {
          padding-left: 15px;
          line-height: 14px;
          margin-bottom: 10px;
        }

        &:checked + label::after {
          left: 3px;
        }

        &:checked + label::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 10px;
          border: 2px solid #fff;
          border-top: 0;
          border-left: 0;
          left: 3px;
          top: 0px;
          transform: rotate(30deg);
        }

        & + label::before {
          position: absolute;
          content: "";
          width: 10px;
          height: 10px;
          border: 1px solid #fff;
          left: 0px;
          top: 2px;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-blue-lightest;
      outline: 1px solid slategrey;
      border-radius: 4px;
    }
  }
}
ul.chipbox {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    color: #97a4b5;
    border: 1px solid #526783;
    display: inline-block;
    padding: 2px 20px 2px 5px;
    margin: 4px 4px 0px 0px;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    line-height: 14px;

    .icn-close {
      float: right;
      position: absolute;
      right: 4px;
      top: 4px;
    }
  }
}
$multiselect-down-offset: 8px;
.multiselect {
  position: relative;
  padding-bottom: $multiselect-down-offset;
  ng-select.ng-select-multiple {
    ng-dropdown-panel {
      div.ng-option > span.ng-option-label {
        position: relative;
        padding-left: 25px;
        &::before {
          position: absolute;
          content: "";
          left: 5px;
          top: 5px;
          width: 16px;
          height: 16px;
          border: 1px solid #162e4d;
        }
        &::after {
          display: none;
        }
      }
      div.ng-option-selected > span.ng-option-label::after {
        display: block !important;
        position: absolute;
        content: "";
        left: 10px;
        top: 6px;
        width: 6px;
        height: 11px;
        border: 0;
        border-right: 2px solid #162e4d;
        border-bottom: 2px solid #162e4d;
        transform: rotate(45deg);
      }
    }
    .ng-select-container {
      position: relative;
      .ng-value-container {
        .ng-value {
          display: none;
        }
        .ng-placeholder {
          display: block;
        }
      }
      .ng-clear-wrapper {
        display: none;
      }
    }
  }
  label {
    position: relative;
    display: block;
    margin: 0;
  }

  .selectBox .form-control {
    color: $color-blue-lighter !important;
  }

  ul {
    padding: 6px;
    margin: 0;
    list-style-type: none;
    border: 1px solid $color-blue-lightest;
    max-height: 175px;
    overflow-y: auto;
    position: absolute;
    left: 0;
    top: 100%;
    background: #fff;
    z-index: 9;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    li {
      position: relative;

      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        z-index: 99;

        & + label {
          padding-left: 18px;
          line-height: 14px;
          margin-bottom: 10px;
        }

        &:checked + label::after {
          position: absolute;
          content: "";
          width: 6px;
          height: 10px;
          border: 2px solid $color-blue;
          border-top: 0;
          border-left: 0;
          left: 4px;
          top: 3px;
          transform: rotate(30deg);
        }

        & + label::before {
          position: absolute;
          content: "";
          width: 14px;
          height: 14px;
          border: 1px solid $color-blue;
          left: 0px;
          top: 2px;
        }
      }
    }

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $color-blue-lightest;
      outline: 1px solid slategrey;
      border-radius: 4px;
    }
  }
}

.multiSelectDropdown,
#checkboxes {
  display: none;
  border: 1px #dadada solid;
  position: absolute;
  left: 0;
  top: 20px;
  margin-top: $multiselect-down-offset;
  width: 100%;
  background: #fff;
  box-shadow: 0px 24px 35px rgba(0, 0, 0, 0.17);
  border-radius: 12px;
  z-index: 9;
  div {
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $color-blue-lightest-2;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-blue-lighter;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  div.checkBoxDiv {
    position: relative;
    :hover {
      color: #678cfc;
      background: #678cfc26;
    }
    input[type="checkbox"] {
      position: absolute;
    }
  }

  label {
    color: #162e4d;
    margin: 0;
    padding: 5px 0;
    padding-left: 25px;
    position: relative;
    display: block;

    &::before {
      position: absolute;
      content: "";
      left: 5px;
      top: 5px;
      width: 16px;
      height: 16px;
      border: 1px solid #162e4d;
    }
  }

  input {
    opacity: 0;
    width: 2px;
    height: 2px;

    &:checked + label::after {
      position: absolute;
      content: "";
      left: 10px;
      top: 6px;
      width: 6px;
      height: 11px;
      border: 0;
      border-right: 2px solid #162e4d;
      border-bottom: 2px solid #162e4d;
      transform: rotate(45deg);
    }
  }
}

.selectBox {
  position: relative;
  cursor: pointer;

  select {
    position: relative;
    padding-top: 0;
  }

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    content: "";
  }
}

#searchFilter .well .form-control.radius,
.inputWithText .form-control.max40 {
  max-width: 40px;
  text-align: right;
  margin-right: 10px;
}

.inputWithText .form-control.max85 {
  max-width: 85px;

  &:focus {
    background: none !important;
  }
}

#searchFilter .well .input-group-text,
.inputWithText .input-group-text {
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  color: #fff;
  font-size: 12px;
}

.searchmap {
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 30px;

  img.img-fluid {
    width: 100%;
  }

  .button {
    position: absolute;
    width: 120px;
    background: #4a90e2;
    text-align: center;
    padding: 2px 5px;
    cursor: pointer;
    border-radius: 8px 8px 0 0;
    bottom: 0;
    left: calc(50% - 60px);
    color: #fff;

    img {
      transform: rotate(180deg);
      margin-bottom: -5px;

      &.down {
        transform: rotate(0deg);
        margin-top: -10px;
      }
    }
  }
}

.compareShow {
  padding: 20px 10px;
  border: 1px solid $color-green;
  border-right: 0;
  color: #4a90e2;
  position: fixed;
  bottom: 50%;
  right: 0;
  z-index: 9;
  background: #eceff5;
  text-align: right;
  min-width: 100px;
  cursor: pointer;
}

.compareShow span {
  color: #4a90e2;
  cursor: pointer;
}

.compareShow span img {
  margin: -5px 0 0 5px;
}

.compare {
  padding: 20px 40px;
  border: 1px solid #4ee17b;
  position: relative;

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .well {
    padding: 20px;
    position: relative;
    margin: 0 5px;
    display: inline-block;
    max-width: 300px;

    .col-3 {
      max-width: 60px;
      padding-right: 0;
    }
  }

  .cross,
  .icn-ltarrow,
  .icn-rtarrow,
  .hideCompare {
    position: absolute;
    cursor: pointer;
    z-index: 2;
  }

  .cross {
    right: 10px;
    top: 10px;
  }

  .icn-ltarrow {
    left: 20px;
    top: 50%;
  }

  .icn-rtarrow {
    right: 20px;
    top: 50%;
  }

  .hideCompare {
    right: 20px;
    top: 20px;
    color: #4a90e2;

    img {
      margin: -5px 0 0 5px;
    }
  }
}

.sliderSection {
  padding: 20px;
  position: relative;

  h3 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .cross,
  .icn-ltarrow,
  .icn-rtarrow,
  .hideCompare {
    position: absolute;
    cursor: pointer;
    z-index: 2;
  }

  .cross {
    right: 10px;
    top: 10px;
  }

  .icn-ltarrow {
    left: 20px;
    top: 50%;
  }

  .icn-rtarrow {
    right: 20px;
    top: 50%;
  }
}

.hide {
  display: none;
}

.bigImg {
  width: 100%;
  max-height: 200px;
}

.smImg {
  width: 25%;
  float: left;
  height: 40px;
}

.smImg.active {
  width: calc(25% - 2px);
  border: 1px solid #4ee17b;
}

.progress {
  height: 4px;
  margin-top: 10px;

  .lg {
    height: 8px;
  }

  &.radarbar {
    height: 8px;

    .progress-bar {
      border-radius: 10px;
      background-color: #16b3af;
    }
  }
}

.product {
  width: 305px;
  float: left;
  background: #2e445f;
  position: relative;
  padding: 20px;

  & + .product {
    margin-left: 15px;
  }
}

.productWIthLinks {
  width: 335px;
  float: left;
  background: #2e445f;
  padding: 15px;

  & + .productWIthLinks {
    margin-left: 10px;
  }
}

.productWIthLinks .img-fluid,
.product .img-fluid {
  min-width: 100%;
  max-height: 40px;
}

.productWIthLinks .col-3,
.product .col-3 {
  padding-right: 0;
}

.productDetails {
  color: #fff;
  min-height: 500px;

  .col-sm-2 {
    width: 227px;
  }

  .col-sm-3 {
    width: 276px;
  }
}

.sidebox {
  background: #526783;
  background: -moz-linear-gradient(
    top,
    #526783 0%,
    $color-blue 90%,
    $color-blue 100%
  );
  background: -webkit-linear-gradient(
    top,
    #526783 0%,
    $color-blue 90%,
    $color-blue 100%
  );
  background: linear-gradient(
    to bottom,
    #526783 0%,
    $color-blue 90%,
    $color-blue 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#526783', endColorstr='$color-blue', GradientType=0);
  padding: 25px 30px;
}

.actionMenu {
  position: relative;

  a:hover {
    text-decoration: none;
  }

  .dnList {
    position: absolute;
    top: 100%;
    box-shadow: 4px 8px 16px 0px rgba(103, 140, 252, 0.15);
    background: #fff;
    right: 0;
    min-width: 100%;
    z-index: 1;

    a {
      display: block;
      text-align: right;
      padding: 3px;
      text-align: left;
      font-weight: normal;
      color: $color-blue-light;

      &:hover {
        background: rgba(103, 140, 252, 0.15);
        color: $color-sky-lightest;
        text-decoration: none;
      }
    }
  }
}

.productListing .col-md-1.col-3,
.productListing .col-3.col-sm-2 {
  max-width: 65px;
  padding-right: 10px;
  margin-bottom: 10px;

  img.img-fluid {
    height: 40px;
    width: 40px;
  }
}

.product .col-3,
.productWIthLinks .col-3 {
  max-width: 55px;
  margin-bottom: 10px;

  img.img-fluid {
    height: 40px;
    min-width: 100%;
  }
}

.product .col-9,
.productWIthLinks .col-9 {
  padding-right: 0;
}

.productListing .font12,
.product .font12,
.productWIthLinks .font12 {
  line-height: 16px;
}

.productListing .btn {
  width: 90px;
  height: 24px;
  padding: 0;
  text-align: center;

  &.fullWidth {
    width: 100%;
  }
  &.onRequest {
    width: 130px;
  }
}

.soldaLot .text-red {
  display: block;
}

.article {
  position: relative;

  &.greybg {
    background: $color-lightbg;
  }
}

.marketplace {
  background: url("../assets/images/marketplace.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 5% 0;

  .well {
    background: none !important;
    border: 0;
    min-height: 220px;
    cursor: default;
    transition: all 0.5s;

    &:hover {
      background: #c9d0da !important;
      box-shadow: 0 0 10px #000;
    }

    .text-center {
      min-height: 120px;
    }
  }
}

.fourBox {
  .well {
    background: #fff !important;
    min-height: 230px;
    cursor: default;
    transition: all 0.5s;

    &:hover {
      box-shadow: 0 0 5px #000;
      background: #c9d0da !important;
    }

    .text-center {
      min-height: 120px;
    }
  }

  .col-md-3:first-child img {
    margin-top: 20px;
  }
}

.bannerHeading {
  padding-top: 35vh;
  max-width: 625px;
  font-weight: 400;
  font-size: 36px;

  span {
    font-weight: 700;
    font-size: 24px;
    color: $color-blue-light;
    display: block;
  }
}

.boxAttachment {
  border: 1px solid #e2e8ef;
  display: inline-block;
  padding: 20px;
  text-align: center;
  word-break: break-all;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 90px;
  min-height: 90px;
  font-size: 14px;
}

.or {
  text-align: center;
  position: relative;
  font-size: 23px;
  font-weight: bold;
  span {
    min-width: 16%;
    display: inline-block;
    margin: 20px auto;
  }
  &::before,
  &::after {
    content: "";
    width: 42%;
    height: 2px;
    background: $color-blue;
    position: absolute;
    left: 0;
    top: 30px;
  }
  &::after {
    left: auto;
    right: 0;
  }
}

.marketplace {
  background: url("../assets/images/marketplace.jpg") no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding: 5% 0;

  .well {
    background: none;
    border: 0;
    min-height: 220px;
    cursor: default;
    transition: all 0.5s;

    .text-center {
      min-height: 120px;
    }
  }

  .fourBox {
    .well {
      background: none !important;

      &:hover {
        background: #fff !important;
      }
    }
  }
}

.fourBox {
  .well {
    background: #fff !important;
    min-height: 230px;
    cursor: default;
    transition: all 0.5s;

    &:hover {
      background: #c9d0da !important;
    }

    .text-center {
      min-height: 120px;
    }
  }

  .col-md-3:first-child img {
    margin-top: 20px;
  }
}

.bannerHeading {
  padding-top: 35vh;
  max-width: 625px;
  font-weight: 400;
  font-size: 36px;

  span {
    font-weight: 700;
    font-size: 24px;
    color: $color-blue-light;
    display: block;
  }
}

accordion-group {
  .card {
    border-radius: 0;
    border: 0;

    .card-header {
      // padding: 0;
      // border: 0;
      // margin-bottom: 10px;
      background-color: white;

      .accordion-toggle {
        button {
          padding: 10px 20px;
          display: block;
          width: 100%;
          text-align: left;
          font-weight: bold;
          font-size: 16px;
          color: #162e4d;
          position: relative;
          background: white;
          border: 0;
          cursor: pointer;

          &:hover {
            text-decoration: none;
          }

          &::after {
            position: absolute;
            content: "";
            left: 10px;
            top: 16px;
            width: 16px;
            height: 16px;
            background: url("../assets/images/career/Polygon 1.png") no-repeat;
          }
        }

        &[aria-expanded="true"] button::after {
          // transform: rotate(90deg);
          background: url("../assets/images/career/Polygon 3.png") no-repeat;
          top: 16px;
        }
      }
    }

    .card-body {
      padding: 5px 30px 30px 30px;
      background-color: $color-lightbg-2;
    }
  }

  .accordion-content {
    padding: 10px 20px;
  }
}

.notification {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    padding: 10px;
    margin: 0 0 10px 0;
    border: none;
    background: $color-white;
    position: relative;
    border-bottom: 12px;
    // color: $color-blue-lighter;

    h3 {
      font-size: 16px;
      padding: 0;
      margin: 0;
      margin-bottom: 4px;
    }

    p {
      padding: 0;
      margin: 0;
    }

    .markas {
      position: absolute;
      right: 10px;
      top: 10px;
      width: 7px;
      height: 7px;
      font: 0;
      background: $color-white;
      border: 1px solid $color-blue-lightest;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    .date {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .action {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    &.unread {
      background: $color-blue-lightest-10;
      border-radius: 8px;
      // color: $color-blue-lighter;

      .markas {
        background: $color-blue-lightest;
      }
    }

    &.read {
      color: $color-blue-lighter;
    }
  }
}

.uploadFile {
  display: inline-block;
}

.attached {
  border: 1px solid #97a4b5;
  border-radius: 8px;
  padding: 1px 8px;
  margin-top: 3px;
  font-size: 14px;
  color: $color-blue-lighter;
  position: relative;
  font-weight: normal;
  word-wrap: break-word;

  span {
    position: absolute;
    top: 5px;
    right: 8px;
    cursor: pointer;
    width: 10px;
    background-size: cover;
    height: 10px;
  }
}

.soldout {
  position: relative;

  &::after {
    content: " ";
    position: absolute;
    background: #fff url("../assets/images/sold-out.png") no-repeat center
      center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0.7;
    text-align: center;
  }
  .deleteBtn {
    position: absolute;
    z-index: 9;
    left: 50%;
    bottom: 15px;
    font-weight: bold;
    transform: translateX(-50%);
  }
}
.onRequestCart {
  background: rgba(247, 249, 251, 0.7);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 65px;
  text-align: center;
  font-weight: bold;
  &.sm {
    padding: 7px;
    line-height: 16px;
  }
}
.margin0 {
  margin: 0px !important;
}

.marginT5 {
  margin-top: 5px !important;
}

.marginT8 {
  margin-top: 8px !important;
}

.marginT10 {
  margin-top: 10px !important;
}

.marginT15 {
  margin-top: 15px !important;
}

.marginT20 {
  margin-top: 20px !important;
}

.marginT30 {
  margin-top: 30px !important;
}

.marginT40 {
  margin-top: 40px !important;
}

.marginB0 {
  margin-bottom: 0px !important;
}

.marginB8 {
  margin-bottom: 8px !important;
}

.marginB10 {
  margin-bottom: 10px !important;
}

.marginB20 {
  margin-bottom: 20px !important;
}

.marginB30 {
  margin-bottom: 30px !important;
}

.marginTB10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.marginTB20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.marginR5 {
  margin-right: 5px !important;
}

.marginT25 {
  margin-top: 25px !important;
}

.marginR10 {
  margin-right: 10px !important;
}

.marginR15 {
  margin-right: 15px !important;
}

.marginL5 {
  margin-left: 5px !important;
}

.marginL10 {
  margin-left: 10px !important;
}

.marginL15 {
  margin-left: 15px !important;
}

.marginL20 {
  margin-left: 20px !important;
}

.marginL30 {
  margin-left: 30px !important;
}

.marginL40 {
  margin-left: 40px !important;
}

.marginR20 {
  margin-right: 20px !important;
}

.marginR30 {
  margin-right: 30px !important;
}

.padding0 {
  padding: 0 !important;
}

.paddingL10 {
  padding-left: 10 !important;
}

.paddingL30 {
  padding-left: 30 !important;
}

.paddingR0 {
  padding-right: 0 !important;
}

.paddingR20 {
  padding-right: 20px !important;
}

.padding10 {
  padding: 10px !important;
}

.padding20 {
  padding: 20px !important;
}

.paddingL0 {
  padding-left: 0 !important;
}

.paddingR0 {
  padding-right: 0 !important;
}

.paddingB0 {
  padding-bottom: 0 !important;
}

.paddingT0 {
  padding-top: 0 !important;
}

.mainContainer {
  height: 100%;
}

@keyframes arrowLink {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0) rotate(45deg);
  }

  40% {
    transform: translateY(-20px) rotate(45deg);
  }

  60% {
    transform: translateY(-15px) rotate(45deg);
  }
}

.animation-opacity {
  animation-name: opacity;
  animation-duration: 2s;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes widthy {
  0% {
    right: -50px;
  }

  100% {
    right: 0;
  }
}

// input[type="range"] {
//   margin: auto;
//   -webkit-appearance: none;
//   position: relative;
//   overflow: hidden;
//   height: 10px;
//   width: 210px;
//   cursor: pointer;
//   border-radius: 8px; __<<ngThemingMigrationEscapedComment27>>__
// }

// ::-webkit-slider-runnable-track {
//   background: #97a4b5;
// }

// __<<ngThemingMigrationEscapedComment28>>__
// ::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   width: 10px; __<<ngThemingMigrationEscapedComment29>>__
//   height: 10px;
//   background: #4a90e2;
//   box-shadow: -100vw 0 0 100vw #4a90e2;
// border-radius:50%;
// border:2px solid #f7f9fb;
// }

// ::-moz-range-track {
//   height: 10px;
//   background: #97a4b5;
// }

// ::-moz-range-thumb {
//   background: #4a90e2;
//   height: 10px;
//   width: 10px;
//   border: 3px solid #999;
//   box-shadow: -100vw 0 0 100vw #4a90e2;
//   box-sizing: border-box;
// border:2px solid #f7f9fb;
// border-radius:50%;
// }

// ::-ms-fill-lower {
//   background: #4a90e2;
// }

// ::-ms-thumb {
//   background: #4a90e2;
//   height: 10px;
//   width: 10px;
//   box-sizing: border-box;
// border:2px solid #f7f9fb;
// border-radius:50%;
// }

// ::-ms-ticks-after {
//   display: none;
// }

// ::-ms-ticks-before {
//   display: none;
// }

// ::-ms-track {
//   background: #97a4b5;
//   color: transparent;
//   height: 40px;
//   border: none;
// }

// ::-ms-tooltip {
//   display: none;
// }
input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  border-radius: 5px;
  background: #97a4b5;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #4a90e2;
  cursor: pointer;
}

.rowsm {
  margin-left: -4px;
  margin-right: -4px;

  .colsm {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.arrowLink {
  width: 15px;
  height: 15px;
  transform: rotate(45deg);
  overflow: hidden;
  font-size: 0;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  position: absolute;
  left: 50%;
  bottom: 5%;
  animation-name: arrowLink;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

.cookie-law-wrapper {
  background: #fff !important;
  padding: 10px !important;
  border: 1px solid #b4b4b4;
  box-shadow: 0 0 10px $color-blue;

  .copy {
    background: url("../assets/images/svg/icn-24-px-info.svg") no-repeat left
      top;
    padding: 0 0px 0 30px !important;

    span {
      font-size: 16px !important;
      color: #162e4d !important;
      padding: 0 !important;
    }

    a {
      color: $color-sky !important;
      text-decoration: none !important;
      font-weight: 700 !important;

      &.dismiss {
        display: none;
      }
    }

    .btn {
      padding: 2px 20px;
    }
  }
}

.calendly-overlay iframe {
  height: 120% !important;
  margin-top: -80px !important;
}

.calendly-overlay .calendly-popup {
  width: 100% !important;
  max-width: 100% !important;
}
.boxxportPostSlider {
  .right,
  .left {
    &::after {
      position: absolute;
      right: -50px;
      top: 25%;
      width: 20px;
      height: 20px;
      content: "";
      border-bottom: 2px solid $color-blue;
      border-right: 2px solid $color-blue;
      transform: rotate(-45deg);
    }
  }
  .left {
    &::after {
      right: calc(100% + 50px);
      transform: rotate(135deg);
    }
  }
}
.boxxportSlider {
  .carousel-indicators {
    margin: 0 7px -30px 7px;
    li {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      opacity: 1;
      margin: 0 7px;
      background-color: #b4b4b4;
      &.active {
        background-color: #16b3af;
      }
    }
  }
  .right,
  .left {
    &::after {
      position: absolute;
      right: -50px;
      top: 25%;
      width: 20px;
      height: 20px;
      content: "";
      border-bottom: 3px solid $color-blue;
      border-right: 3px solid $color-blue;
      transform: rotate(-45deg);
    }
  }
  .left {
    &::after {
      right: calc(100% + 50px);
      transform: rotate(135deg);
    }
  }
}
.news,
.blogs {
  .carousel:focus,
  .carousel:focus-visible,
  .carousel:active,
  .carousel-inner:focus,
  .carousel-inner:focus-visible,
  .carousel-inner:active {
    outline: none;
    box-shadow: none;
    border: 0;
  }
  .carousel-indicators {
    display: none;
  }
  .right,
  .left {
    &::after {
      position: absolute;
      right: -20px;
      top: calc(50% - 10px);
      width: 30px;
      height: 30px;
      content: "";
      border-bottom: 3px solid $color-blue;
      border-right: 3px solid $color-blue;
      transform: rotate(-45deg);
    }
  }
  .left {
    &::after {
      right: 100%;
      transform: rotate(135deg);
    }
  }
  .carousel-control-prev,
  .carousel-control-next {
    width: 30px;
  }
}

.whyus {
  padding-bottom: 10vh;
  .carousel:focus,
  .carousel:focus-visible,
  .carousel:focus-within,
  .carousel:active,
  .carousel-inner:focus,
  .carousel-inner:focus-visible,
  .carousel-inner:active {
    outline: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
  }
  .carousel-indicators {
    margin: 0 7px -70px 7px;
    li {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      opacity: 1;
      margin: 0 7px;
      background-color: #fff;
      &.active {
        background-color: #16b3af;
      }
    }
  }
  .right,
  .left {
    &::after {
      position: absolute;
      right: -50px;
      top: 45%;
      width: 35px;
      height: 35px;
      content: "";
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
      transform: rotate(-45deg);
    }
    span {
      display: none;
    }
  }
  .left {
    &::after {
      right: calc(100% + 50px);
      transform: rotate(135deg);
    }
  }
}
@media only screen and (max-width: 650px) {
  .calendly-overlay .calendly-popup-close {
    background-color: #b4b4b4 !important;
    border-radius: 50% !important;
    background-size: 60% !important;
    background-position: 4px !important;
    margin-right: 10px !important;
  }
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .mainBg {
    img,
    video {
      transform: scale(1.2);
    }
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mainBg {
    img,
    video {
      transform: scale(1.2);
    }
  }
}

//animatiion css
// .first .midDiv{
//   transition: all 0.5s;
//   border-radius: 10px;
//   &:hover{
//     box-shadow: 0px 150px 27px -100px #000;
//   }
// }

@media only screen and (max-width: 1024px) {
  input[type="range"] {
    width: 100%;
  }

  table.table-tab {
    tbody td {
      width: auto !important;
    }
  }

  .popover.font16 {
    display: none !important;
  }

  .minWidth .withLeftBorder ul li.nav-item {
    width: auto;
    max-width: auto;
    min-width: auto;
  }
  .news,
  .blogs {
    .right,
    .left {
      &::after {
        right: -7px;
        top: calc(50% - 10px);
        width: 15px;
        height: 15px;
      }
    }
    .left {
      &::after {
        right: calc(100% - 7px);
      }
    }
  }
}

@media only screen and (max-width: 1023px) {
  table.table-tab {
    thead {
      display: none;
    }

    tbody {
      td {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    .btn {
      display: block;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .main {
    min-height: calc(100vh - 65px);
  }

  .productDetails .col-sm-4.col-md-3 {
    max-width: 242px;
    padding-right: 0;
  }

  .productDetails .col-sm-12.col-md-3 {
    min-width: 242px;
    padding-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .mainBg {
    position: absolute;

    video,
    img {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      width: auto;
    }
  }

  #container {
    padding: 20px;
    padding-bottom: 0;
    margin: 0;
  }

  footer nav.navbar {
    padding: 20px;
  }

  footer .nav .nav-link {
    margin: 0 10px 0 0;
    padding-left: 5px;
    padding-right: 5px;
  }

  .seller {
    width: 100%;
    transform: translate(0);
    float: none;
  }

  .content,
  .midDiv {
    padding: 20px;
  }

  .centerDiv.w-50 {
    width: 100% !important;
    padding: 20px;
  }

  .middle {
    width: 90%;
  }

  .borderDiv,
  .greenDiv {
    position: static;
    width: 100%;
  }

  .showDiv {
    padding-top: 0;
    background: #526783;
    height: 40px;
    width: 80px;

    .btn,
    .btn:hover {
      padding: 6px;
      padding-left: 20px;
      margin: 0;

      &::after {
        width: 8px;
        height: 8px;
        top: 16px;
        left: 10px;
        border-width: 2px;
      }
    }
  }

  .rightSection {
    margin-left: 10px;
  }

  .sideSection {
    width: calc(100% - 72px);
    background: $color-blue;
    height: calc(100% - 65px);
    padding-bottom: 0;
    top: auto;
    bottom: 0;

    table {
      table-layout: fixed;
    }
  }

  .borderedBody,
  .tableResponsive,
  .spriptedBorder {
    thead {
      display: none;
    }

    tbody {
      tr td {
        display: block;
        width: 100%;
        text-align: center;
        border: 1px solid $color-blue-lighter;

        &:last-child {
          text-align: center;
          border: 0;
        }
      }
    }
  }

  .selling,
  .buying,
  .team {
    background-size: 100% !important;
    background-position: center bottom;
  }

  .selling,
  .buying,
  .team,
  .about,
  .career {
    .container {
      width: 90%;
      background: #fff;
      opacity: 0.7;
      padding: 20px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .bannerHeading,
      h1 {
        padding-top: 0 !important;
        min-height: auto;
      }
    }
  }

  .tableWithHeading {
    thead {
      display: none;
    }

    tbody {
      td {
        padding-left: 50% !important;
        text-align: left !important;
        position: relative;
        border: 1px solid #c0c8d4;
        border-bottom: 0;
        display: block;

        &::before {
          content: attr(data-title);
          position: absolute;
          top: 10px;
          left: 10px;
          width: 45%;
          padding-right: 10px;
          white-space: nowrap;
        }
      }
    }
  }
  .news,
  .blogs {
    .right,
    .left {
      &::after {
        right: -20px;
      }
    }
    .left {
      &::after {
        right: calc(100% + 7px);
      }
    }
  }
  .team .container {
    top: 100px;
    transform: translate(0px, 0px);
    left: 5%;
    h1.bannerHeading {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 576px) {
  #wrapper {
    margin: 0;
    height: auto;
    min-height: auto;
  }

  .midDiv,
  .centerDiv {
    position: static;
    transform: none;
    margin: 0px auto;
    padding: 20px 0;
  }

  .font36 {
    font-size: 20px;
    line-height: 24px;
  }

  .font24 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
  }

  .middle h1 {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
  }

  .middle h2 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }

  footer {
    position: static !important;
    font-size: 12px;
  }

  footer .nav .nav-link {
    padding: 0;
  }

  .content,
  .midDiv {
    width: 100%;
  }

  .borderLR:before,
  .borderLR:after {
    border: 0;
  }

  .modal-body {
    padding: 20px;
  }

  .defaultAccountPopup {
    width: 95%;
  }

  .stepper {
    margin: 0;
    margin-bottom: 20px;
  }

  .stepper ul {
    margin: 0;
  }

  .stepper li span.info {
    font-size: 0;
  }

  .rightSection {
    width: calc(100% - 90px);
  }

  .middle {
    width: auto;
  }

  .nav-tabs {
    .nav-link {
      padding: 5px;
      font-size: 12px;
    }
  }

  .stepper li span.details {
    padding-top: 0px;
    font-size: 0;
  }

  .stepper li.active span.details {
    font-size: 12px;
  }

  .middle .form-group .withInfo {
    bottom: -15px;
    font-size: 12px;
  }

  .topNav li {
    padding-left: 10px;
  }

  input[type="range"] {
    width: 100%;
  }

  .second h1,
  .second p {
    max-width: 100%;
    margin: 0px;
    padding-top: 20px;
  }

  .seventh {
    background-size: 100% 100%;

    .imgContainer {
      div {
        position: static;
        padding: 10%;
        min-width: 320px;
      }

      img {
        display: none;
      }
    }
  }

  .arrowLink {
    display: none;
  }

  #searchFilter {
    padding-right: 15px;
  }

  .productWIthLinks {
    width: 80vw;
  }

  .compare {
    padding: 20px;

    .rotateLeft {
      left: 5px;
    }

    .rotateRight {
      right: 5px;
    }

    .product {
      width: 85vw;
    }
  }

  .productListing {
    .marginL20.text-blue {
      margin-left: 5px !important;
    }
  }

  .borderRight {
    border: 0;
  }

  .notification {
    li {
      .date,
      .action {
        position: static;
      }
    }
  }

  .table .actiondn {
    .btn {
      display: none;
    }

    ul {
      display: block;
      background: none;
      box-shadow: none;
      position: static;
      border: 0;

      li {
        a {
          text-align: center;
          color: $color-sky;
          font-weight: bold;

          &:hover {
            color: $color-blue;
          }
        }
      }
    }
  }
  .boxxportSlider {
    .right,
    .left {
      &::after {
        right: -35px;
        top: 100px;
      }
    }
    .left {
      &::after {
        right: calc(100% + 20px);
      }
    }
  }
  .news,
  .blogs {
    .right,
    .left {
      &::after {
        right: 15px;
        border-color: #fff;
      }
    }
    .left {
      &::after {
        right: calc(100% - 30px);
      }
    }
  }
}

//@import 'header.scss';

// Theme CSS
.light {
  background: #fff;

  .navbar {
    .nav .nav-item .nav-link {
      color: $color-blue;

      &.active {
        background: none;
      }
    }
  }

  .main {
    color: $color-blue;
    background: $color-blue-lightest-10;
    // border-top: 1px solid #e8edf4;
  }

  .nav-link.active {
    background: $color-blue;
    color: #fff;
  }

  #searchFilter .well,
  .well,
  .sidebox,
  .alert-blue,
  .draganddrop {
    background: $color-lightbg;
    border-color: $color-blue-lightest;

    &.sm {
      padding: 10px;
    }
  }

  #searchFilter {
    input[type="checkbox"] + label::before,
    input[type="checkbox"]:checked + label::before,
    input[type="checkbox"]:checked + label::after {
      border-color: $color-blue;
    }

    .form-control {
      color: $color-blue;
      border-color: $color-blue;

      &::placeholder {
        color: $color-blue;
      }
    }
  }

  #searchFilter .well .input-group-text,
  .inputWithText .input-group-text {
    color: $color-blue;
  }

  .middle select.form-control,
  .middle select.form-control:focus {
    background-image: url("../assets/images/svg/icn-dropdown.svg");
  }

  .filter select {
    color: $color-blue;
    border-color: $color-blue;
  }

  .form-control {
    color: $color-blue;
    border-color: $color-blue;
  }

  select.form-control {
    background: url("../assets/images/svg/icn-dropdown.svg") no-repeat right;
  }

  #searchFilter .well .form-control.location {
    background: url("../assets/images/svg/icn-location.svg") no-repeat right;
  }

  .middle .has-error .form-control {
    border-color: $color-blue;
  }

  .color-white {
    color: $color-blue;

    &:hover {
      text-decoration: none;
      color: $color-blue;
    }
  }

  .seller {
    color: $color-blue;

    .form-control {
      color: $color-blue;
      border-color: $color-blue;
    }
  }

  .stepper ul li {
    &::before {
      border-color: $color-blue-lightest;
    }

    .steps {
      color: #fff;
    }

    &.active .steps {
      background: url(../assets/images/svg/oval-blue.svg) no-repeat;
    }

    &.success .steps {
      color: #4ee17b;
    }

    span.info {
      opacity: 0.5;
    }

    &.active {
      span.info,
      span.details {
        color: $color-blue;
        opacity: 1;
      }
    }

    &.active:nth-child(1) span.info {
      background: url("../assets/images/svg/icn-60-px-inactive-personal-details.svg")
        no-repeat center 10px;
      background-size: 40px;
    }

    &.active:nth-child(2) span.info {
      background: url("../assets/images/svg/icn-60-px-inactive-briefcase.svg")
        no-repeat center 10px;
      background-size: 40px;
    }

    &.active:nth-child(3) span.info {
      background: url("../assets/images/svg/icn-60-px-inactive-money.svg")
        no-repeat center 15px;
      background-size: 40px;
    }
    &.active:nth-child(4) span.info {
      background: url("../assets/images/svg/icn-60-px-inactive-video-verfication.svg")
        no-repeat center 15px;
      background-size: 40px;
    }
  }

  .stars span {
    background-image: url("../assets/images/svg/star-active-orange.svg");
  }

  .productDetails,
  .well h3 a,
  .productListing h3 a {
    color: $color-blue;
  }

  .product,
  .productWIthLinks {
    background: $color-lightbg;
    border: 1px solid #eceff5;

    .text-white {
      color: $color-blue !important;
    }
  }

  .sliderSection {
    color: $color-blue !important;
  }

  input[type="radio"].form-check-input + label:before {
    border-color: $color-blue;
  }

  input[type="radio"].form-check-input:checked + label:after {
    background: $color-blue;
  }

  .form-check input[type="checkbox"]:checked + label:before,
  .form-check input[type="checkbox"]:checked + label:after {
    border-color: $color-blue;
  }

  .form-group input.form-control.ng-invalid.ng-touched,
  .hasErrorSpan {
    color: #d0021b;
    border-color: #d0021b;
    line-height: 14px;
  }

  .rightSection {
    background: #f7f8fb;
    color: $color-blue;

    .middle {
      color: $color-blue;

      .input-group .input-group-prepend span,
      .input-group .input-group-append span {
        color: $color-blue;
      }
    }
  }

  .sidebarbuyer,
  .sidebar {
    background: $color-lightbg;

    a.active,
    a:hover {
      background: $color-blue-lightest;
    }
  }

  .sideSection {
    background: #fff;
    color: $color-blue;
    border: 1px solid $color-blue-lightest;

    .defaultBankAccountDTO {
      background: $color-lightbg;
      border-bottom: 1px solid $color-blue-lightest;
    }

    hr {
      border-color: $color-blue-lightest;
      margin: 10px;
    }
  }

  .showDiv {
    background: $color-lightbg;
    color: $color-blue;
    box-shadow: 0 0 0 1px $color-blue-lightest inset;

    .btn::after {
      border-color: #162e4d;
    }
  }

  .icn-search {
    background: url("../assets/images/svg/magnifying-glass-dark.svg") no-repeat;
  }

  // .icn-cart {
  //   background: url("../assets/images/new-svg/carticon.svg") no-repeat;
  // }

  .icn-plus {
    background: url("../assets/images/svg/x.svg") no-repeat;
  }

  .icn-chat {
    background-image: url("../assets/images/svg/chat-blue.svg");
  }

  .icn-alarm {
    background-image: url("../assets/images/new-svg/notificationicon.svg");
  }

  .icn-logout {
    background-image: url("../assets/images/svg/logout-blue.svg");
  }

  // .icn-user {
  //   background-image: url("../assets/images/new-svg/usericon.svg");
  // }

  .icn-logo {
    background: url("../assets/images/svg/logo1-blue.svg") no-repeat;
  }

  .icn-home {
    background: url("../assets/images/svg/home-blue.svg") no-repeat;
  }

  .icn-sign {
    background: url("../assets/images/svg/sign-blue.svg") no-repeat;
  }

  .icn-banknote {
    background: url("../assets/images/svg/banknote.svg") no-repeat;
  }

  .icn-list {
    background: url("../assets/images/svg/list-1-blue.svg") no-repeat;
  }

  .icn-bar-chart {
    background: url("../assets/images/svg/bar-chart-blue.svg") no-repeat;
  }

  .my-style {
    background: #000;
    color: #fff;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .nav-tabs .minWidth .withLeftBorder ul li:first-child {
    top: -1px;
  }

  .nav-link.active {
    position: relative;
    top: 0px;
  }
}

.normal {
  font-weight: 400 !important;
}

.blue-text {
  color: #4a90e2;
}

.disabled-checkbox:hover {
  cursor: not-allowed !important;
}
